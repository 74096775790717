// export const primaryColor = "#0D99FF";
export const primaryColor = "#8C271D";
export const secondaryColor = "#ECE8DC";
export const thirdColor = "#E5E5E5";
export const textColor = "#545454";
export const parentColor = "#DAD4C4";
export const grayLighter = "#ECE8DC";
export const PaginationArrow = "#6C6C6C";
export const InputsBorderColor = "#ECE8DC";
export const buttonBackground = "#ECE8DC";
export const CommentAndActivityBackground = "#F3F3F3";
export const gray = "#B3B3B3";
export const GreenColor = "#14AE5C";
export const BgCardColor = "#E6E6E6";
export const SearchButton = "#ffa629";
export const violet = "#4A249D";
