/**
    this function handle open or close items child and for this menu spicificly
    if user clicked on child element because child cant be parent we dont allow to
    setState then we prevent useless rerender
    * @param {function} setOpen
    * @param  {boolean} open
    * @param  {string} child
    * @returns {function}
*/
export const handleOpenClose = (setOpen, open, child) => {
  return () => {
    child && setOpen(!open);
  };
};

/**
 *
 * @param {string} path
 * @param {function} navigate
 * @returns {void}
 */
export const goToPage = (path, navigate) => {
  navigate(path);
};

/**
 *
 * @param {string} path
 * @param {string} windowPath
 * @returns {string}
 */

export const isActive = (path, windowPath) => {
  return path === windowPath ? "true" : "false";
};
