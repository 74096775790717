import { PersonContainer } from "./personStyles/person.style";

const Person = () => {
  return (
    <PersonContainer>
      <svg
        width="28"
        height="19"
        viewBox="0 0 28 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.6173 23.746C3.87421 19.2604 8.46821 16.4093 13.5724 16.4093C18.6773 16.4093 23.2706 19.2612 25.5275 23.746H1.6173H1.61652H1.6173ZM6.8276 7.96169C6.8276 4.26369 9.85332 1.25481 13.5724 1.25481C17.2914 1.25481 20.3172 4.26291 20.3172 7.96169C20.3172 11.6558 17.2914 14.6639 13.5724 14.6639C9.85411 14.6639 6.8276 11.655 6.8276 7.96169ZM27.095 24.1351C25.9999 21.5059 24.1683 19.2706 21.7958 17.6688C20.1458 16.5531 18.3047 15.7937 16.3686 15.4186C19.41 14.2896 21.5812 11.3737 21.5812 7.96169C21.5812 3.56909 17.9887 0 13.5716 0C9.15526 0 5.56198 3.56831 5.56198 7.96169C5.56198 11.3729 7.7332 14.2888 10.7746 15.4186C8.83925 15.7937 6.9974 16.5523 5.34737 17.6688C2.97569 19.2706 1.14407 21.5059 0.0482359 24.1351C-0.0327329 24.3249 -0.0107228 24.5468 0.106407 24.7226C0.224323 24.8984 0.421636 25 0.633098 25H26.5109C26.7216 25 26.9189 24.8984 27.0368 24.7226C27.1539 24.5468 27.1751 24.3242 27.095 24.1351Z"
          fill="#545454"
        />
      </svg>
    </PersonContainer>
  );
};

export default Person;
