import { styled, Box, Button } from "@mui/material";

export const LoginContainer = styled(Box)(() => ({
  width: "100%",
  height: `${window.innerHeight}px`,
  display: "flex",
}));
export const ButtonLogin = styled(Button)(() => ({
  width: "450",
  height: "45",
  background: "#4A249D",
  color: "white",
  borderRadius: "5",
}));

export const RightSide = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flex: "3",
  backgroundColor: "#FFFFFF",
  overflowY: "scroll",
}));

export const FormWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

export const FieldWrapper = styled(Box)(() => ({
  marginTop: "20px",
}));

export const FieldSMS = styled(Box)(() => ({
  direction: "ltr",
  margin: "1rem auto",
  display: "flex",
  justifyContent: "center",
  ".ReactInputVerificationCode__item": {
    position: "relative",
    color: "#262626",
    fontWeight: "500",
    borderBottom: "1px solid #000",
    height: "60px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export const ForgottenText = styled(Box)(() => ({
  color: "#545454",
  textAlign: "center",
  cursor: "pointer",
}));
export const FleetsRegister = styled(Box)(() => ({
  color: "#3FA2F6",
  textAlign: "center",
  cursor: "pointer",
}));

export const LeftSide = styled(Box)(({ backgroundPhoto }) => ({
  background: `url(${backgroundPhoto})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  flex: "4",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
}));

export const LoginText = styled("p")(() => ({
  fontSize: "27px",
  lineHeight: "1",
  color: "#FFFFFF",
}));

export const LoginTextContainer = styled(Box)(() => ({
  margin: "0 41px 95px 0",
}));
