const Star = () => {
  return (
    <div style={{ margin: "0 0 0 4px" }}>
      <svg
        width="11"
        height="10"
        viewBox="0 0 11 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.957031 2.70703L4.35547 3.92578L4.22656 0.328125H6.33594L6.14844 3.91406C6.71094 3.71875 7.29297 3.51562 7.89453 3.30469C8.49609 3.08594 9.07812 2.88281 9.64062 2.69531C9.75 3.07031 9.85938 3.41797 9.96875 3.73828C10.0859 4.05078 10.207 4.39844 10.332 4.78125L6.75781 5.75391C7.13281 6.21484 7.52344 6.6875 7.92969 7.17188C8.34375 7.65625 8.73828 8.125 9.11328 8.57812L7.36719 9.80859L5.29297 6.83203L3.21875 9.80859L1.50781 8.57812L3.78125 5.75391L0.277344 4.75781L0.957031 2.70703Z"
          fill="#8C271D"
        />
      </svg>
    </div>
  );
};

export default Star;
