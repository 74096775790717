import StrinLocalized from "../../Language/language.lang";
import Button from "../../components/Inputs/Button/button.component";
import DatePicker from "@hassanmojab/react-modern-calendar-datepicker";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import {
  FormLabel,
  InputRow,
  Fields,
  SubmitContainer,
  InputColumn,
  SubmitBox,
} from "./RegisterAgentStyles/registerAgent.style";
import { primaryColor, violet } from "../../colors/allColors.color";
import TextBox from "../../components/Inputs/TextBox/textBox.component";
import StarIcon from "../../Icons/FormsIcon/Star/Star.icon";
import { useForm, Controller } from "react-hook-form";
import UploadInput from "../../components/Inputs/UploadInput/UploadInput.component";
import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  API_URL,
  API_URL_COMMON,
  API_URL_FLEET,
} from "../../HelperAxios/const";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../HelperAxios/axios";
import SelectBox from "../../components/Inputs/SelectBox/SelectBox.component";
import useSignIn from "react-auth-kit/hooks/useSignIn";

const commonStyles = {
  bordder: 1,
};

const RegisterCarOwner = () => {
  const { handleSubmit, control } = useForm();
  const [nationalCardPic, setNationalCardPic] = useState([]);
  const [notarizedPic, setNotarizedPic] = useState([]);
  const [phone, setPhone] = useState("");
  const [showSms, setShowSms] = useState(false);
  const navigate = useNavigate();
  const [selectedDay, setSelectedDay] = useState(null);
  const [convertDate, setConvertDate] = useState(null);
  const signIn = useSignIn();
  const renderCustomInput = ({ ref }) => (
    <input
      readOnly
      ref={ref} // necessary
      //   value={selectedDay ? `: ${selectedDay.day}` : ""}
      className="my-custom-input-class" // a styling class
    />
  );
  useEffect(() => {
    if (selectedDay) {
      setConvertDate(
        `${selectedDay.month}/${selectedDay.day}/${selectedDay.year}`
      );
    }
    // getAll(setAgents, "/fleet/list");
  }, [selectedDay]);

  /**** upload photo ***/
  const UploadNationalPic = async (data) => {
    const formData = new FormData();
    const index = data.index;
    formData.append("file", data.file);
    formData.append("title", data.file.name);
    formData.append("field_name", "national_card_url");
    toast.info(StrinLocalized.sendData);
    console.log(formData);
    await axios({
      method: "post",
      url: `${API_URL_COMMON}/upload/document`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        toast.success(StrinLocalized.SuccessAction);
        console.log(res.data);
        setNationalCardPic(() => {
          return [
            {
              // url: res.data.data.uploadedFile.url,
              // index: index,
              // field: res.data.data.field_name,
            },
          ];
        });
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        return err;
      });
  };
  const UploadNotarizedPicPic = async (data) => {
    const formData = new FormData();
    const index = data.index;
    formData.append("file", data.file);
    formData.append("title", data.file.name);
    formData.append("fileName", "notarized_form_url");
    toast.info(StrinLocalized.sendData);
    console.log(formData);
    await axios({
      method: "post",
      url: `${API_URL_COMMON}/upload/document`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        toast.success(StrinLocalized.SuccessAction);
        setNotarizedPic(() => {
          return [
            {
              // url: res.data.data.uploadedFile.url,
              // index: index,
              // field: res.data.data.field_name,
            },
          ];
        });
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        return err;
      });
  };
  // const SendSms = () => {
  //   toast.info(StrinLocalized.sendData);
  //   axiosInstance
  //     .post(
  //       "https://api.nikohamrah.com/api/v1/portal/auth/user/car-owner/create-send-sms",
  //       { mobile: phone },
  //       {
  //         headers: [{ "Accept-Language": "fa" }],
  //       }
  //     )
  //     .then((res) => {
  //       toast.success(StrinLocalized.SuccessAction);
  //       setShowSms(true);
  //       console.log(res);
  //     })
  //     .catch((err) => {
  //       toast.error(err.response.data.message);
  //     });
  // };
  /*** submit form ***/
  const onSubmit = (data) => {
    toast.info(StrinLocalized.sendData);
    if (!data.password || data.password.length < 8) {
      toast.error("Password must be more than 8 characters");
      return;
    }

    // Check if the passwords are exactly the same character by character
    const passwordsMatch =
      data.password.length === data.repeat.length &&
      [...data.password].every((char, index) => char === data.repeat[index]);
    if (passwordsMatch) {
      data = {
        // code: data.validationCode,
        username: data?.username,
        password: data?.password,
        retype_password: data?.repeat,
        name: data?.name,
        surname: data?.surname,
        mobile: data?.mobile,
        email: data?.email,
        id_card_number: data?.personal_code,
        birthday: convertDate,
        company_address: data?.company_address,
        postal_code: data?.postal_code,
        company_register_number: data?.company_register_number,
        id_card_image: notarizedPic[0]?.url,
        other_documents: nationalCardPic[0]?.url,
      };
      console.log(data);
      axios
        .post(API_URL_FLEET + "/auth/register", data)
        .then((res) => {
          let data = res.data.data;
          console.log(data);
          if (data) {
            localStorage.setItem("token-niko", data.token.access_token);
            localStorage.setItem(
              "token-niko",
              JSON.stringify(data.token.access_token)
            );
            if (
              signIn({
                auth: { token: data.token.access_token, type: "Bearer" },
                userState: data.user,
                refresh_token: data.token.access_token,
              })
            ) {
              setTimeout(() => {
                if (
                  data.user.role === "صاحب ماشین" ||
                  data.user.role === "نماینده ماشین"
                ) {
                  navigate("/dashboard/carowner");
                } else if (data.user.role === "راننده خودرو") {
                  navigate("/dashboard/driver");
                } else if (data.user.role === "نماینده شرکت") {
                  navigate("/dashboard/company");
                } else {
                  navigate("/dashboard/admin");
                }
              }, 2500);
            } else {
              toast.error("Something went wrong");
            }
            localStorage.setItem("user-niko", JSON.stringify(data?.user));
            localStorage.setItem("role-niko", btoa(data?.roles));
            localStorage.setItem("permissions-niko", btoa(data?.permissions));
          }
          toast.success(StrinLocalized.SuccessLogin);
        })
        .catch((error) => {
          toast.error(error.response?.data.message);
        });
      // axiosInstance
      //   .post(`${API_URL_FLEET}/auth/register`, data)
      //   .then(() => {
      //     toast.success(StrinLocalized.SuccessAction);
      //     setTimeout(() => {
      //       navigate("/login");
      //     }, 2000);
      //   })
      //   .catch((err) => {
      //     toast.error(err.response.data.message);
      //   });
    } else {
      toast.error("Password don't match with password Again");
    }
  };

  return (
    <div>
      <div
        style={{
          background: violet,
          width: "100%",
          height: "70px",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
          fontSize: "16px",
          fontWeight: "700",
        }}
      >
        <span>{StrinLocalized.SignUpCarOwner}</span>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Fields>
          <InputColumn>
            <FormLabel>
              {StrinLocalized.UserName}
              <StarIcon />
            </FormLabel>
            <Controller
              name="username"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value = "" } }) => (
                <TextBox
                  required={true}
                  sx={{ ...commonStyles, borderRadius: 1 }}
                  placeHolder={StrinLocalized.Name}
                  width={1000}
                  height={38}
                  onChange={onChange}
                  value={value}
                  rounded
                />
              )}
            />
          </InputColumn>
          <InputColumn>
            <FormLabel>
              {StrinLocalized.Password}
              <StarIcon />
            </FormLabel>
            <Controller
              name="password"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value = "" } }) => (
                <TextBox
                  required={true}
                  sx={{ ...commonStyles, borderRadius: 1 }}
                  placeHolder={StrinLocalized.Name}
                  width={1000}
                  height={38}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </InputColumn>
          <InputColumn>
            <FormLabel>
              {StrinLocalized.Repeat}
              <StarIcon />
            </FormLabel>
            <Controller
              name="repeat"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value = "" } }) => (
                <TextBox
                  required={true}
                  sx={{ ...commonStyles, borderRadius: 1 }}
                  placeHolder={StrinLocalized.Name}
                  width={1000}
                  height={38}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </InputColumn>
          <InputColumn>
            <FormLabel>
              {StrinLocalized.Name}
              <StarIcon />
            </FormLabel>
            <Controller
              name="name"
              control={control}
              // rules={{ required: true }}
              render={({ field: { onChange, value = "" } }) => (
                <TextBox
                  required={true}
                  sx={{ ...commonStyles, borderRadius: 1 }}
                  placeHolder={StrinLocalized.Name}
                  width={1000}
                  height={38}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </InputColumn>
          <InputColumn>
            <FormLabel>
              {StrinLocalized.SurName}
              <StarIcon />
            </FormLabel>
            <Controller
              name="surname"
              control={control}
              // rules={{ required: true }}
              render={({ field: { onChange, value = "" } }) => (
                <TextBox
                  required={true}
                  sx={{ ...commonStyles, borderRadius: 1 }}
                  placeHolder={StrinLocalized.SurName}
                  width={1000}
                  height={38}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </InputColumn>
          <InputColumn>
            <FormLabel>
              {StrinLocalized.Phone}
              <StarIcon />
            </FormLabel>

            <Controller
              name="mobile"
              control={control}
              // rules={{ required: true }}
              render={({ field: { onChange, value = "" } }) => (
                <TextBox
                  required={true}
                  sx={{ ...commonStyles, borderRadius: 1 }}
                  placeHolder={StrinLocalized.Phone}
                  width={1000}
                  height={38}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </InputColumn>
          <InputColumn>
            <FormLabel>
              {StrinLocalized.Email}
              <StarIcon />
            </FormLabel>
            <Controller
              name="email"
              control={control}
              // rules={{ required: true }}
              render={({ field: { onChange, value = "" } }) => (
                <TextBox
                  required={true}
                  sx={{ ...commonStyles, borderRadius: 1 }}
                  placeHolder={StrinLocalized.Email}
                  width={1000}
                  height={38}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </InputColumn>
          <InputColumn>
            <FormLabel>
              {StrinLocalized.PersonalCode}
              <StarIcon />
            </FormLabel>
            <Controller
              name="personal_code"
              control={control}
              // rules={{ required: true }}
              render={({ field: { onChange, value = "" } }) => (
                <TextBox
                  required={true}
                  sx={{ ...commonStyles, borderRadius: 1 }}
                  placeHolder={StrinLocalized.PersonalCode}
                  width={1000}
                  height={38}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </InputColumn>

          <InputColumn>
            {" "}
            <FormLabel>
              {StrinLocalized.BirthDay}
              {/* <StarIcon /> */}
            </FormLabel>
            <div className="w-[1000px]">
              <div className="flex w-full border px-5 rounded-sm">
                <input
                  defaultValue={convertDate}
                  placeholder="7/28/2024"
                  onChange={(e) => setConvertDate(e.target.value)}
                  aria-label="birthday"
                  type="text"
                  className="w-full h-[38px] outline-none"
                  // {...register("birthday", { required: true })}
                />
                <DatePicker
                  value={selectedDay}
                  onChange={setSelectedDay}
                  renderInput={renderCustomInput} // render a custom input
                  shouldHighlightWeekends
                  calendarClassName="my-custom-calender"
                />
              </div>
            </div>
          </InputColumn>

          <div className="w-full mt-32"></div>
          <InputColumn>
            <FormLabel>
              {StrinLocalized.CompanyAddress}
              <StarIcon />
            </FormLabel>
            <Controller
              name="company_address"
              control={control}
              // rules={{ required: true }}
              render={({ field: { onChange, value = "" } }) => (
                <TextBox
                  required={true}
                  sx={{ ...commonStyles, borderRadius: 1 }}
                  placeHolder={StrinLocalized.CompanyAddress}
                  width={1000}
                  height={38}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </InputColumn>
          <InputColumn>
            <FormLabel>
              {StrinLocalized.PostalCode}
              <StarIcon />
            </FormLabel>
            <Controller
              name="postal_code"
              control={control}
              // rules={{ required: true }}
              render={({ field: { onChange, value = "" } }) => (
                <TextBox
                  required={true}
                  sx={{ ...commonStyles, borderRadius: 1 }}
                  placeHolder={StrinLocalized.PostalCode}
                  width={1000}
                  height={38}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </InputColumn>
          <InputColumn>
            <FormLabel>
              {StrinLocalized.CompanyRegisterNumber}
              <StarIcon />
            </FormLabel>
            <Controller
              name="company_register_number"
              control={control}
              // rules={{ required: true }}
              render={({ field: { onChange, value = "" } }) => (
                <TextBox
                  required={true}
                  sx={{ ...commonStyles, borderRadius: 1 }}
                  placeHolder={StrinLocalized.CompanyRegisterNumber}
                  width={1000}
                  height={38}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </InputColumn>
          <InputColumn>
            <FormLabel>
              {StrinLocalized.PersonalCardPicture}
              <StarIcon />
            </FormLabel>
            <UploadInput
              placeHolder={StrinLocalized.PersonalCardPicture}
              width={900}
              height={38}
              index={notarizedPic.index ? notarizedPic.index : 0}
              uploadValue={UploadNotarizedPicPic}
              sx={{ ...commonStyles, borderRadius: 1 }}
            />
          </InputColumn>
          <InputColumn>
            <FormLabel>
              {StrinLocalized.OtherDocuments}
              <StarIcon />
            </FormLabel>

            <UploadInput
              placeHolder={StrinLocalized.OtherDocuments}
              width={900}
              height={38}
              index={nationalCardPic.index ? nationalCardPic.index : 0}
              uploadValue={UploadNationalPic}
              sx={{ ...commonStyles, borderRadius: 1 }}
            />
          </InputColumn>

          <SubmitBox>
            <Button
              sx={{ ...commonStyles, borderRadius: "16px" }}
              textcolor="white"
              buttonText={StrinLocalized.Submit}
              type="submit"
              background={violet}
              width={200}
            />
          </SubmitBox>
        </Fields>
      </form>
      <ToastContainer rtl={true} />
    </div>
  );
};
export default RegisterCarOwner;
