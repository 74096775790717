import StringLocalized from "../../../Language/language.lang";

import ReportsIcon from "../../../Icons/sidebarIcons/Reports/reports.icon";

const SideBarData = [
  {
    title: StringLocalized.Dashboard,
    icon: <ReportsIcon />,
    path: "/dashboard/admin",
    role: "user",
    accessRole: "admin-dashboard",
    single: false,
  },
  {
    title: "Guide",
    icon: <ReportsIcon />,
    path: "/help",
    single: true,
  },
  {
    title: "Entry and exit logs",
    icon: <ReportsIcon />,
    path: "/login-logout-log",
    role: "logReport",
    accessRole: "list",
    single: true,
  },
  {
    title: StringLocalized.DashboardCarOwner,
    icon: <ReportsIcon />,
    path: "/dashboard/carowner",
    role: "user",
    accessRole: "carowner-dashboard",
    single: false,
  },
  {
    title: StringLocalized.DashboardDriver,
    icon: <ReportsIcon />,
    path: "/dashboard/driver",
    role: "user",
    accessRole: "driver-dashboard",
    single: false,
  },
  {
    title: StringLocalized.DashboardCompany,
    icon: <ReportsIcon />,
    path: "/dashboard/company",
    role: "company",
    accessRole: "dashboard",
    single: false,
  },
  {
    title: StringLocalized.DashboardShield,
    icon: <ReportsIcon />,
    path: "/dashboard/shield",
    role: "company",
    accessRole: "dashboard",
    single: false,
  },
  {
    title: StringLocalized.DashboardOperator,
    icon: <ReportsIcon />,
    path: "/dashboard/operator",
    role: "user",
    accessRole: "operator-dashboard",
    single: false,
  },
  {
    title: StringLocalized.orders,
    icon: <ReportsIcon />,
    child: [
      {
        title: StringLocalized.ordersList,
        icon: <ReportsIcon />,
        path: "/orders",
        role: "passenger-trip",
        accessRole: "admin-passTrip-list",
      },
      {
        title: StringLocalized.ListArchive,
        icon: <ReportsIcon />,
        path: "/archive-passengers",
        role: "passenger-trip",
        accessRole: "admin-passTrip-archive-list",
      },
      {
        title: StringLocalized.ListOffers,
        icon: <ReportsIcon />,
        path: "/list-offers",
        role: "offer",
        accessRole: "list",
      },
    ],
  },
  {
    title: StringLocalized.everydayAccess,
    icon: <ReportsIcon />,
    child: [
      {
        title: StringLocalized.TravelUsersList,
        icon: <ReportsIcon />,
        path: "/passenger-travels-list",
        role: "passenger-trip",
        accessRole: "list",
      },
      {
        title: StringLocalized.TravelDriverList,
        icon: <ReportsIcon />,
        path: "/driver-travels-list",
        role: "driver-trip",
        accessRole: "list",
      },
      {
        title: StringLocalized.TravelPoint,
        icon: <ReportsIcon />,
        path: "/point-travels-list",
        role: "driver-trip",
        accessRole: "list",
      },
      {
        title: StringLocalized.CheckingActionsDriver,
        icon: <ReportsIcon />,
        path: "/action-drivers",
        role: "null",
        accessRole: "list",
      },
      {
        title: StringLocalized.TotalCarsAndTrip,
        icon: <ReportsIcon />,
        path: "/total-cars-and-trip",
        role: "driver-trip",
        accessRole: "special-path-admin-list",
      },
      {
        title: StringLocalized.MonthlyReport,
        icon: <ReportsIcon />,
        role: "money_reports",
        path: "/monthly-report",
      },
    ],
  },
  {
    title: StringLocalized.Registers,
    icon: <ReportsIcon />,
    parent: "admin",
    role: "access_register",
    accessRole: "read_menu",
    child: [
      {
        title: StringLocalized.ManagementAdmins,
        icon: <ReportsIcon />,
        path: "/admins",
        role: "user",
        accessRole: "list",
      },
      {
        title: StringLocalized.LastChange,
        icon: <ReportsIcon />,
        path: "/last-changes",
        role: "user",
        accessRole: "list",
      },
      {
        title: StringLocalized.LastChangeAdmin,
        icon: <ReportsIcon />,
        path: "/last-changes-users",
        role: "user",
        accessRole: "list",
      },
      {
        title: StringLocalized.UserGroups,
        icon: <ReportsIcon />,
        path: "/user-groups",
        role: "roles",
        accessRole: "list-all-roles",
      },
      {
        title: StringLocalized.Companies,
        icon: <ReportsIcon />,
        path: "/companies",
        role: "company",
        accessRole: "list",
      },
      {
        title: StringLocalized.ListPassenger,
        icon: <ReportsIcon />,
        path: "/passengers",
        role: "passenger",
        accessRole: "list",
      },
      {
        title: StringLocalized.DriverList,
        icon: <ReportsIcon />,
        path: "/drivers",
        role: "user",
        accessRole: "driver-list",
      },
      {
        title: StringLocalized.CarsList,
        icon: <ReportsIcon />,
        path: "/cars",
        role: "car",
        accessRole: "list",
      },
      {
        title: StringLocalized.DeletedCarsList,
        icon: <ReportsIcon />,
        path: "/deleted-cars",
        role: "car",
        accessRole: "deleted-list",
      },
      {
        title: StringLocalized.ListTotalTravelAndTrips,
        icon: <ReportsIcon />,
        path: "/total-travel-and-trip/no",
        role: "car",
        accessRole: "list-car-trip",
      },
      {
        title: StringLocalized.ListTotalTravelAndTripsDeleted,
        icon: <ReportsIcon />,
        path: "/total-travel-and-trip/yes",
        role: "car",
        accessRole: "list-car-trip",
      },
      {
        title: StringLocalized.ListCarOwner,
        icon: <ReportsIcon />,
        path: "/car-owners",
        role: "user",
        accessRole: "carowner-list",
      },
      {
        title: StringLocalized.ListAgent,
        icon: <ReportsIcon />,
        path: "/agents",
        role: "user",
        accessRole: "agent-list",
      },
    ],
  },
  {
    title: StringLocalized.DynamicItems,
    icon: <ReportsIcon />,
    child: [
      {
        title: StringLocalized.OriginsAndDestinations,
        icon: <ReportsIcon />,
        path: "/origin-and-destinations",
        role: "city",
        accessRole: "list",
      },
      {
        title: StringLocalized.Routes,
        icon: <ReportsIcon />,
        path: "/routes",
        role: "road",
        accessRole: "list",
      },
      {
        title: StringLocalized.ListCommissionCoefficient,
        icon: <ReportsIcon />,
        path: "/commission-coefficient",
        role: "commission",
        accessRole: "list",
      },
      {
        title: StringLocalized.Driverscommission,
        icon: <ReportsIcon />,
        path: "/drivers-commission",
        role: "car-commission",
        accessRole: "list",
      },
      {
        title: StringLocalized.InterRouteFormulaItems,
        icon: <ReportsIcon />,
        path: "/road-fares",
        role: "road-fare",
        accessRole: "list",
      },
      {
        title: StringLocalized.DriverAndPassengerFines,
        icon: <ReportsIcon />,
        path: "/driver-and-passenger-fines",
        role: "penalty",
        accessRole: "get",
      },
      {
        title: StringLocalized.FareIncreaseHour,
        icon: <ReportsIcon />,
        path: "/fare-increase-hour",
        role: "fare-increase",
        accessRole: "list",
      },
      {
        title: StringLocalized.TravelItems,
        icon: <ReportsIcon />,
        path: "/travel-items",
        role: "null",
        accessRole: "list",
      },
      {
        title: StringLocalized.CategorySupport,
        icon: <ReportsIcon />,
        path: "/category-support",
        role: "feedback-category",
        accessRole: "list",
      },
      {
        title: StringLocalized.StoppingTime,
        icon: <ReportsIcon />,
        path: "/stopping-time",
        role: "stop-time",
        accessRole: "list",
      },
      {
        title: StringLocalized.ListOfMotionGraphics,
        icon: <ReportsIcon />,
        path: "/motion-graphics",
        role: "motion-graphic",
        accessRole: "list",
      },
      {
        title: StringLocalized.SafetyShieldList,
        icon: <ReportsIcon />,
        path: "/safety-shields",
        role: "safety-shield",
        accessRole: "list",
      },
    ],
  },
  {
    title: StringLocalized.SupportsRequest,
    icon: <ReportsIcon />,
    path: "/support-requests",
    role: "feedback",
    accessRole: "list",
    single: false,
  },
  {
    title: StringLocalized.SupportHistory,
    icon: <ReportsIcon />,
    path: "/support-history-list",
    role: "feedback",
    accessRole: "list-feedback-history",
  },
  {
    title: StringLocalized.MessageAndNoti,
    icon: <ReportsIcon />,
    child: [
      {
        title: StringLocalized.SmsList,
        icon: <ReportsIcon />,
        path: "/sms",
        role: "sms",
        accessRole: "list",
      },
      {
        title: StringLocalized.SendSms,
        icon: <ReportsIcon />,
        path: "/send-sms",
        role: "sms",
        accessRole: "create",
      },
      {
        title: StringLocalized.SendOneNotification,
        icon: <ReportsIcon />,
        path: "/send-notification",
        role: "notif",
        accessRole: "create",
      },
      {
        title: StringLocalized.ListNotification,
        icon: <ReportsIcon />,
        path: "/notifications",
        role: "notif",
        accessRole: "list",
      },
      {
        title: StringLocalized.SmsListCarOwner,
        icon: <ReportsIcon />,
        path: "/sms-car-owner",
        role: "sms",
        accessRole: "get-by-carOwner-or-agent",
      },
      // {
      //   title: StringLocalized.SendGroupNoti,
      //   icon: <ReportsIcon />,
      //   path: "/send-group-noti",
      //   role: "null",
      //   accessRole: "list",
      // },
    ],
  },
  {
    title: StringLocalized.MainSettings,
    icon: <ReportsIcon />,
    path: "/main-setting",
    role: "settings",
    accessRole: "create",
    single: false,
  },
  {
    title: StringLocalized.Reports,
    icon: <ReportsIcon />,
    child: [
      {
        title: StringLocalized.PassengerOnMap,
        icon: <ReportsIcon />,
        path: "/passenger-on-map",
        role: "map",
        accessRole: "passenger-map",
      },
      {
        title: StringLocalized.DriverOnMap,
        icon: <ReportsIcon />,
        path: "/driver-on-map",
        role: "map",
        accessRole: "driver-map",
      },
      {
        title: StringLocalized.SpecialRouteCompany,
        icon: <ReportsIcon />,
        path: "/special-route-companies",
        role: "driver-trip",
        accessRole: "special-path-company-list",
        single: false,
      },
      {
        title: StringLocalized.SpecialRouteCarOwner,
        icon: <ReportsIcon />,
        path: "/special-route-car-owners",
        role: "driver-trip",
        accessRole: "special-path-car-owners-list",
        single: false,
      },
      {
        title: StringLocalized.RequestListOfSelectedTrips,
        icon: <ReportsIcon />,
        path: "/request-selected-trip",
        role: "null",
        accessRole: "list",
      },
      {
        title: StringLocalized.ListSeparateListCars,
        icon: <ReportsIcon />,
        path: "/separate-cars",
        role: "driver-trip",
        accessRole: "special-path-car-list",
      },
      {
        title: StringLocalized.BlockedAdmin,
        icon: <ReportsIcon />,
        path: "/users-blocked-admin",
        role: "driver-trip",
        accessRole: "special-path-car-list",
      },
      {
        title: StringLocalized.BlockedCarOwner,
        icon: <ReportsIcon />,
        path: "/users-blocked-carowner",
        role: "driver-trip",
        accessRole: "special-path-car-list",
      },

      {
        title: StringLocalized.MonthlyExpenceReport,
        icon: <ReportsIcon />,
        path: "/monthly-expense-report",
        role: "report",
        accessRole: "monthly-admin-expense",
      },
      {
        title: StringLocalized.MonthlyIncomeReport,
        icon: <ReportsIcon />,
        path: "/monthly-income-report",
        role: "report",
        accessRole: "monthly-admin-income",
      },
      {
        title: StringLocalized.MonthlyReport,
        icon: <ReportsIcon />,
        path: "/monthly-report",
        role: "report",
        accessRole: "monthly-trips-admin",
      },
      {
        title: StringLocalized.BlockedAdmin,
        icon: <ReportsIcon />,
        path: "/blocked-admin",
        role: "null",
        accessRole: "monthly-trips-admin",
      },
      {
        title: StringLocalized.BlockedCarOwner,
        icon: <ReportsIcon />,
        path: "/blocked-car-owner",
        role: "null",
        accessRole: "monthly-trips-admin",
      },
    ],
  },
  {
    title: StringLocalized.FincaneReport,
    icon: <ReportsIcon />,
    parent: "access_report_money",
    role: "access_report_money",
    accessRole: "read_menu",
    child: [
      {
        title: StringLocalized.Wallet,
        icon: <ReportsIcon />,
        path: "/wallet/car",
        role: "credit",
        accessRole: "list-car",
      },
      {
        title: StringLocalized.MicrotransactionsAll,
        icon: <ReportsIcon />,
        path: "/admin-microtransactions",
        role: "transaction",
        accessRole: "list-all-admin",
      },
      {
        title: StringLocalized.MyMicrotransactions,
        icon: <ReportsIcon />,
        path: "/my-microtransactions",
        role: "transaction",
        accessRole: "list-my",
      },
      {
        title: StringLocalized.payList,
        icon: <ReportsIcon />,
        path: "/request-pays",
        role: "withdrawal-request",
        accessRole: "list",
      },
      {
        title: StringLocalized.Microtransactions,
        icon: <ReportsIcon />,
        path: "/microtransactions",
        role: "transaction",
        accessRole: "list",
      },
      {
        title: StringLocalized.ScoreBagInternalUserAdmin,
        icon: <ReportsIcon />,
        path: "/score-bag-admin",
        role: "scorebag",
        accessRole: "list-admin",
      },
      {
        title: StringLocalized.ScoreBagInternalUser,
        icon: <ReportsIcon />,
        path: "/score-bag",
        role: "scorebag",
        accessRole: "list",
      },
    ],
  },
  {
    title: StringLocalized.PointsAndAwards,
    icon: <ReportsIcon />,
    child: [
      {
        title: StringLocalized.SixMonthlyDriverPoints,
        icon: <ReportsIcon />,
        path: "/drivers/monthly-point",
        role: "award-history",
        accessRole: "total-semester-list",
      },
      {
        title: StringLocalized.YearlyDriverPoints,
        icon: <ReportsIcon />,
        path: "/drivers/yearly-point",
        role: "award-history",
        accessRole: "total-annual-list",
      },
      {
        title: StringLocalized.MonthlyPassengerPoints,
        icon: <ReportsIcon />,
        path: "/passenger/monthly-point",
        role: "award-history",
        accessRole: "total-semester-list",
      },
      {
        title: StringLocalized.YearlyPassengerPoints,
        icon: <ReportsIcon />,
        path: "/passenger/yearly-point",
        role: "award-history",
        accessRole: "total-annual-list",
      },
      {
        title: StringLocalized.MonthlyGift,
        icon: <ReportsIcon />,
        path: "/drivers-and-passenger/monthly-point",
        role: "award-history",
        accessRole: "total-semester-list",
      },
      {
        title: StringLocalized.YearlyGift,
        icon: <ReportsIcon />,
        path: "/drivers-and-passenger/yearly-point",
        role: "award-history",
        accessRole: "total-annual-list",
      },
      {
        title: StringLocalized.RewardSetting,
        icon: <ReportsIcon />,
        path: "/reward-setting",
        role: "award-setting",
        accessRole: "create",
      },
    ],
  },
  {
    title: StringLocalized.Secretariat,
    icon: <ReportsIcon />,
    child: [
      {
        title: StringLocalized.ListCategoriesDepartedSecretariat,
        icon: <ReportsIcon />,
        path: "/secretariat-categories",
        role: "secretariat-category",
        accessRole: "list",
      },
      {
        title: StringLocalized.ListDepartedSecretariat,
        icon: <ReportsIcon />,
        path: "/list-departed-secretariat",
        role: "departed-secretariat",
        accessRole: "list",
      },
      {
        title: StringLocalized.AddDepartedSecretariat,
        icon: <ReportsIcon />,
        path: "/add-departed-secretariat",
        role: "departed-secretariat",
        accessRole: "create",
      },
      {
        title: StringLocalized.ListDepartedReceived,
        icon: <ReportsIcon />,
        path: "/list-departed-received",
        role: "incoming-secretariat",
        accessRole: "list",
      },
      {
        title: StringLocalized.AddDepartedReceived,
        icon: <ReportsIcon />,
        path: "/add-departed-received",
        role: "incoming-secretariat",
        accessRole: "create",
      },
    ],
  },
  {
    title: StringLocalized.Tickets,
    icon: <ReportsIcon />,
    child: [
      {
        title: StringLocalized.TicketCategoriesList,
        icon: <ReportsIcon />,
        path: "/ticket-categories",
        role: "ticket-category",
        accessRole: "list",
      },
      {
        title: StringLocalized.TicketsList,
        icon: <ReportsIcon />,
        path: "/tickets",
        role: "ticket",
        accessRole: "list",
      },
      {
        title: StringLocalized.SendTicket,
        icon: <ReportsIcon />,
        path: "/add-ticket",
        role: "ticket",
        accessRole: "create",
      },
      {
        title: StringLocalized.SendTicketAgent,
        icon: <ReportsIcon />,
        path: "/add-ticket-agent",
        role: "ticket",
        accessRole: "create",
      },
      {
        title: StringLocalized.logTicket,
        icon: <ReportsIcon />,
        path: "/log-tickets",
        role: "ticket",
        accessRole: "status-history-list",
      },
      {
        title: StringLocalized.SendTicket,
        icon: <ReportsIcon />,
        path: "/send-ticket",
        role: "tickets",
        accessRole: "list",
      },
      {
        title: StringLocalized.SeeTicket,
        icon: <ReportsIcon />,
        path: "/see-tickets",
        role: "tickets",
        accessRole: "list",
      },
      {
        title: StringLocalized.Archive,
        icon: <ReportsIcon />,
        path: "/archive-ticket",
        role: "tickets",
        accessRole: "list",
      },
    ],
  },
  {
    title: StringLocalized.SuperintendentsReport,
    icon: <ReportsIcon />,
    child: [
      {
        title: StringLocalized.SuperintendentsTicketReport,
        icon: <ReportsIcon />,
        path: "/superintendents-ticket-report",
        role: "nazer_reports",
        accessRole: "list",
      },
      {
        title: StringLocalized.ReportOfOprators,
        icon: <ReportsIcon />,
        path: "/operators-report",
        role: "nazer_reports",
        accessRole: "list",
      },
    ],
  },
  {
    title: StringLocalized.InternalTicket,
    icon: <ReportsIcon />,
    path: "/internal-ticket",
    role: "ticket",
    accessRole: "internal-ticket",
    single: false,
  },
  {
    title: StringLocalized.InternalUsers,
    icon: <ReportsIcon />,
    path: "/internal-user",
    role: "ticket",
    accessRole: "internal-ticket",
    single: false,
  },
  {
    title: StringLocalized.CarOwnerAccess,
    icon: <ReportsIcon />,
    parent: "carowner",
    role: "access_carowner",
    accessRole: "read_menu",
    child: [
      {
        title: StringLocalized.EditCarOwnerBySelf,
        icon: <ReportsIcon />,
        // path: "/cars-list2",
        path: "/edit-carowner-by-self",
        role: "user",
        accessRole: "carowner-update",
      },
      {
        title: StringLocalized.CarsList,
        icon: <ReportsIcon />,
        // path: "/cars-list2",
        path: "/cars-owner",
        role: "car",
        accessRole: "list",
      },
      {
        title: StringLocalized.DeletedCarsList,
        icon: <ReportsIcon />,
        path: "/deleted-cars-owner",
        role: "car",
        accessRole: "deleted-list",
      },
      // {
      //   title: StringLocalized.TravelUsersList,
      //   icon: <ReportsIcon />,
      //   path: "/passenger-travels-list",
      //   role: "passenger-trip",
      //   accessRole: "list",
      // },
      // {
      //   title: StringLocalized.TravelDriverList,
      //   icon: <ReportsIcon />,
      //   path: "/driver-travels-list",
      //   role: "driver-trip",
      //   accessRole: "list",
      // },
      // {
      //   title: StringLocalized.TravelPoint,
      //   icon: <ReportsIcon />,
      //   path: "/point-travels-list",
      //   role: "passenger-trip",
      //   accessRole: "list-scored-trips",
      // },
      {
        title: StringLocalized.DriverList,
        icon: <ReportsIcon />,
        path: "/carowner-drivers",
        role: "user",
        accessRole: "driver-list",
      },
      {
        title: StringLocalized.DriverSmallNewList,
        icon: <ReportsIcon />,
        path: "/carowner-new-drivers",
        role: "user",
        accessRole: "driver-list",
      },
      {
        title: StringLocalized.DriverDetailsInNowTrip,
        icon: <ReportsIcon />,
        path: "/status-driver-current-trip",
        role: "driver-trip",
        accessRole: "current-trips-list",
      },
      {
        title: StringLocalized.ListTotalTravelAndTrips,
        icon: <ReportsIcon />,
        path: "/total-travel-and-trip/no",
        role: "car",
        accessRole: "list-car-trip",
      },
      {
        title: StringLocalized.ListTotalTravelAndTripsDeleted,
        icon: <ReportsIcon />,
        path: "/total-travel-and-trip/yes",
        role: "car",
        accessRole: "list-car-trip",
      },
      {
        title: StringLocalized.CheckingActionsDriver,
        icon: <ReportsIcon />,
        path: "/action-drivers",
        role: "null",
        accessRole: "list",
      },
      // {
      //   title: StringLocalized.Wallet,
      //   icon: <ReportsIcon />,
      //   path: "/wallet/car",
      //   role: "credit",
      //   accessRole: "list-car",
      // },
      {
        title: StringLocalized.UsersNote,
        icon: <ReportsIcon />,
        path: "/user-notes",
        role: "note",
        accessRole: "passengerTrip-list",
      },
      {
        title: StringLocalized.Support,
        icon: <ReportsIcon />,
        path: "/support-requests-by-car-owner",
        role: "feedback",
        accessRole: "list",
      },
      {
        title: StringLocalized.AccessRegisterCar,
        icon: <ReportsIcon />,
        path: "/access-register-car",
        role: "user",
        accessRole: "agent-check-access",
      },
      {
        title: StringLocalized.MonthlyReport,
        icon: <ReportsIcon />,
        path: "/monthly-report-carowner",
        role: "report",
        accessRole: "monthly-trips-carOwner",
      },
      {
        title: StringLocalized.RegisterAndEditAgent,
        icon: <ReportsIcon />,
        path: "/add-agent-carowner",
        role: "user",
        accessRole: "agent-create",
      },
      {
        title: StringLocalized.MonthlyMicrotransactions,
        icon: <ReportsIcon />,
        path: "/microtransactions-carowner",
        role: "transaction",
        accessRole: "list",
      },
    ],
  },
  {
    title: StringLocalized.AccessDriver,
    icon: <ReportsIcon />,
    parent: "driver",
    role: "access_driver",
    accessRole: "read_menu",
    child: [
      {
        title: StringLocalized.IncreaseWallet,
        icon: <ReportsIcon />,
        path: "/increase-wallet",
        role: "credit",
        accessRole: "charge-credit",
      },
      {
        title: StringLocalized.RequestPay,
        icon: <ReportsIcon />,
        path: "/request-pay-self/user",
        role: "withdrawal-request",
        accessRole: "create",
      },
      {
        title: StringLocalized.MonthlyReport,
        icon: <ReportsIcon />,
        path: "/monthly-report-driver",
        role: "report",
        accessRole: "monthly-trips-driver",
      },
      {
        title: StringLocalized.MonthlyMicrotransactions,
        icon: <ReportsIcon />,
        path: "/microtransactions-driver",
        role: "transaction",
        accessRole: "list",
      },
    ],
  },
  {
    title: StringLocalized.Logout,
    icon: <ReportsIcon />,
    single: true,
  },
];

export default SideBarData;
