import { styled, Box } from "@mui/material";
// import { thirdColor } from "../../colors/allColors.color";

export const Main = styled(Box)(
  ({ width, dynamicBackground, background, widthSidebar }) => ({
    width: `${width ? width : 97}%`,
    margin: "0 auto",
    display: "flex",
    height: `${window.innerHeight - 160}px`,
    backgroundColor: dynamicBackground ? dynamicBackground : background, //the main color before get's dynamic is 'thirdColor'
    " > div:nth-child(2)": {
      maxWidth: widthSidebar === "f1" ? "86% !important" : "100%",
    },
  })
);

export const LayoutWrapper = styled(Box)(
  ({ background, dynamicBackground }) => ({
    backgroundColor: dynamicBackground ? dynamicBackground : background, //the main color before get's dynamic is 'thirdColor'
  })
);
