import { FooterWrapper } from "./footerStyles/footer.style";
import LogoAkaf from "../../assets/footerLogo.png";

const Footer = () => {
  return (
    <FooterWrapper>
      کلیه حقوق معنوی این نرم افزار برای گروه نیکوهمراه محفوظ می باشد.طراحی و
      پیاده سازی نرم افزار توسط شرکت آکاف
      <img src={LogoAkaf} alt="akaf-logo" />
    </FooterWrapper>
  );
};

export default Footer;
