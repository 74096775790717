const Reports = () => {
  return (
    <div>
      <svg
        width="15"
        height="16"
        viewBox="0 0 15 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask id="path-1-inside-1_800_562" fill="white">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 0H15V16H12V0ZM6 5H9V16H6V5ZM3 10H0V16H3V10Z"
          />
        </mask>
        <path
          d="M15 0H16V-1H15V0ZM12 0V-1H11V0H12ZM15 16V17H16V16H15ZM12 16H11V17H12V16ZM9 5H10V4H9V5ZM6 5V4H5V5H6ZM9 16V17H10V16H9ZM6 16H5V17H6V16ZM0 10V9H-1V10H0ZM3 10H4V9H3V10ZM0 16H-1V17H0V16ZM3 16V17H4V16H3ZM15 -1H12V1H15V-1ZM16 16V0H14V16H16ZM12 17H15V15H12V17ZM11 0V16H13V0H11ZM9 4H6V6H9V4ZM10 16V5H8V16H10ZM6 17H9V15H6V17ZM5 5V16H7V5H5ZM0 11H3V9H0V11ZM1 16V10H-1V16H1ZM3 15H0V17H3V15ZM2 10V16H4V10H2Z"
          fill="#545454"
          mask="url(#path-1-inside-1_800_562)"
        />
      </svg>
    </div>
  );
};

export default Reports;
