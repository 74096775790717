import { primaryColor } from "../../../colors/allColors.color";
import { Box, styled } from "@mui/material";

export const HeaderWrapper = styled(Box)(({ headerColor }) => ({
  width: "100%",
  height: "85px",
  //main color before get's dynamic is 'primaryColor'
  backgroundColor: headerColor ? headerColor : primaryColor,
  display: "flex",
  justifyContent: "center",
}));

export const HeaderContainer = styled(Box)(() => ({
  width: "90%",
  height: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));
