import PropTypes from "prop-types";
// import Select from "@mui/material/Select";
import Select from "react-select";
// import MenuItem from "@mui/material/MenuItem";
import React from "react";
import { StyledButton } from "./selectBoxStyles/customSelect.style";

const SelectBox = ({
  width,
  placeHolder,
  height,
  options,
  border,
  marginLeft,
  marginRight,
  right,
  background,
  value,
  defaultValue,
  onChange,
  isDisabled = false,
}) => {
  return (
    <StyledButton
      width={width}
      height={height}
      marginRight={marginRight}
      marginLeft={marginLeft}
      right={right}
    >
      {/*<Select*/}
      {/*  value={value}*/}
      {/*  size="small"*/}
      {/*  sx={{*/}
      {/*    width: ["100%", "100%", width],*/}
      {/*  }}*/}
      {/*  placeHolder={placeHolder}*/}
      {/*  defaultValue={defaultValue}*/}
      {/*  onChange={onChange}*/}
      {/*  style={{*/}
      {/*    height: height,*/}
      {/*    background: background ? `${background}` : "#fff",*/}
      {/*    border: border ? `1px solid #000` : "none",*/}
      {/*    // borderRadius: "14px",*/}
      {/*    fontWeight: "700",*/}
      {/*    fontFamily: "yekan-bakh",*/}
      {/*  }}*/}
      {/*>*/}
      {/*  {options?.map((item) => {*/}
      {/*    return (*/}
      {/*      <MenuItem*/}
      {/*        style={{ fontFamily: "yekan-bakh" }}*/}
      {/*        key={item.id}*/}
      {/*        value={item.id}*/}
      {/*      >*/}
      {/*        {item.name}*/}
      {/*      </MenuItem>*/}
      {/*    );*/}
      {/*  })}*/}
      {/*</Select>*/}

      <Select
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            width: width ? width : "571px",
            background: background,
            border: border,
          }),
        }}
        className="basic-single"
        classNamePrefix="select"
        placeholder={placeHolder}
        isClearable={true}
        isRtl={true}
        name="role"
        defaultValue={defaultValue}
        options={options}
        value={value}
        onChange={onChange}
        isDisabled={isDisabled}
      />
    </StyledButton>
  );
};

SelectBox.propTypes = {
  width: PropTypes.any,
  placeHolder: PropTypes.string.isRequired,
  height: PropTypes.number,
  options: PropTypes.arrayOf(PropTypes.any),
  border: PropTypes.bool,
  marginLeft: PropTypes.string,
  marginRight: PropTypes.string,
  right: PropTypes.number,
  background: PropTypes.string,
  value: PropTypes.any,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export default SelectBox;
