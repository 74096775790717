import { Box, styled, Typography } from "@mui/material";
import { primaryColor, textColor } from "../../../colors/allColors.color";

export const TopMenuContainer = styled(Box)(() => ({
  width: "100%",
  height: "28px",
  borderBottom: "1.5px solid #FFFFFF",
  padding: "5px 0",
  color: textColor,
  // maxWidth: "1200px",
  "@media(max-width: 768px)": {
    height: "auto",
  },
}));

export const ListWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  height: "100%",
  marginRight: "25%",
  marginLeft: "20%",
  justifyContent: "space-between",
  "@media(max-width: 1250px)": {
    marginRight: "0",
    marginLeft: "0",
    flexWrap: "wrap",
  },
}));

export const Sidebar = styled(Box)(() => ({
  position: "absolute",
  right: "22px",
  "@media(max-width: 1250px)": {
    position: "relative",
    right: "5px",
  },
}));

/**
 * @param {string} active
 */
export const ListItem = styled(Typography)(({ active }) => ({
  margin: "0 16px",
  color: active === "true" ? primaryColor : "inherit",
  fontFamily: "sajafFontBold",
  fontSize: "12px",
  // fontSize: "1vw",
  whiteSpace: "nowrap",
  // fontSize: "1rem",
  "@media(max-width: 768px)": {
    margin: "5px 16px",
  },
}));
