import { Btn } from "./buttonStyles/button.style";
import PropTypes from "prop-types";
import Dollars from "../../../Icons/tableIcon/Dollars/dollars.icon";

const Button = ({
  width,
  buttonText,
  height,
  background,
  textcolor,
  fontSize,
  click,
  marginBottom,
  marginLeft,
  marginRight,
  rounded,
  type,
  loading,
  iconDollar = false,
}) => (
  <Btn
    width={width}
    height={height}
    background={background}
    buttontextcolor={textcolor}
    fontSize={fontSize}
    onClick={click}
    marginbottom={marginBottom}
    marginLeft={marginLeft}
    marginRight={marginRight}
    rounded={rounded}
    type={type}
    loading={loading}
  >
    {buttonText}
    {iconDollar ? <Dollars color="#fff" style={{ margin: "0 8px" }} /> : <></>}
  </Btn>
);

// Component prop types
Button.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  buttonText: PropTypes.string.isRequired,
  background: PropTypes.string,
  textcolor: PropTypes.string,
  fontSize: PropTypes.number,
  click: PropTypes.func,
  marginBottom: PropTypes.number,
  marginLeft: PropTypes.number,
  marginRight: PropTypes.number,
  rounded: PropTypes.bool,
  type: PropTypes.string,
  loading: PropTypes.bool,
  iconDollar: PropTypes.bool,
};

export default Button;
