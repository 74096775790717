import { Suspense, useEffect, useState } from "react";
import { CookiesProvider } from "react-cookie";
import { Route, Routes } from "react-router-dom";
import Login from "./Pages/Login/login.page";
import { allRoutes } from "./Routes/routes.route";
import Layout from "./Layout/layout";
import LoginByPhone from "./Pages/Login/loginByPhone.page";
import RegisterCarOwner from "./Pages/RegisterCarOwner/RegisterCarOwnerLogin.page";
import store from "./Utils/auth/store";
import AuthProvider from "react-auth-kit";
import { useFCM } from "./fcm/useFcm";

function App() {
  const { loadToken } = useFCM();

  useEffect(() => {
    try {
      loadToken();
    } catch (error) {
      console.log(error);
    }
  }, []);
  return (
    <AuthProvider store={store}>
      <CookiesProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/login-by-phone" element={<LoginByPhone />} />
          <Route path="/add-car-owner-login" element={<RegisterCarOwner />} />
          <Route path="/" element={<Layout />}>
            {allRoutes.map(({ path, component: Component }, index) => (
              <Route
                key={index}
                path={path}
                element={
                  <Suspense fallback={<div>loading...</div>}>
                    <Component />
                  </Suspense>
                }
              />
            ))}
          </Route>
        </Routes>
      </CookiesProvider>
    </AuthProvider>
  );
}

export default App;
