import { Button, styled } from "@mui/material";
import {
  buttonBackground,
  textColor,
} from "../../../../colors/allColors.color";

/**
 * @param {number} width
 * @param {number} height
 * @param {string} background
 * @param {string} buttontextcolor
 */
export const Btn = styled(Button)(
  ({
    width,
    height,
    background,
    buttontextcolor,
    fontSize,
    marginbottom,
    marginLeft,
    marginRight,
    rounded,
  }) => ({
    backgroundColor: background ? background : buttonBackground,
    fontSize: fontSize && `${fontSize}px`,
    textTransform: "none",
    fontFamily: "sajafFont",
    width: width ? `${width}px` : "100%",
    height: height ? `${height}px` : "100%",
    color: buttontextcolor ? buttontextcolor : textColor,
    "&:hover": {
      backgroundColor: background,
    },
    marginBottom: `${marginbottom ? marginbottom : 0}px`,
    marginLeft: `${marginLeft ? marginLeft : 0}px`,
    marginRight: `${marginRight ? marginRight : 0}px`,
    borderRadius: `${rounded ? "20px" : "0"}`,
  })
);
