import MainMenuIcon from "../../Icons/sidebarIcons/mainMenu/mainMenu.icon";
import { SideBarWrapper, SideBarList } from "./sidebarStyles/sideBar.style";
import SideBarData from "./sidebarData/sideBar.data";
import ListItem from "./listItem.component";
import proptypes from "prop-types";
import { useEffect, useState } from "react";
import { getAll } from "../../Utils/getAll";
import CheckPermission from "../../Utils/checkPermission";
import checkPermission from "../../Utils/checkPermission";

export default function NestedList({ subMenuColor, menuColor, widthSidebar }) {
  const token = localStorage.getItem("token-niko");
  const [permissions, setPermissions] = useState([]);
  const [sideBar, setSideBar] = useState(SideBarData);

  useEffect(() => {
    getAll(setPermissions, "permissions/my");
  }, []);

  if (permissions?.length > 0) {
    localStorage.setItem("niko-perm", JSON.stringify(permissions));
    sideBar?.map((item) => {
      if (item.child) {
        if (item.parent) {
          if (CheckPermission(item.role, item.accessRole, permissions)) {
            Object.keys(item.child).map((key) => {
              if (
                !CheckPermission(
                  item.child[key].role,
                  item.child[key].accessRole,
                  permissions
                )
              ) {
                delete item.child[key];
              }
            });
            return item;
          } else {
            Object.keys(item.child).map((key) => {
              delete item.child[key];
            });
          }
        } else {
          Object.keys(item.child).map((key) => {
            if (
              !CheckPermission(
                item.child[key].role,
                item.child[key].accessRole,
                permissions
              )
            ) {
              delete item.child[key];
            }
          });
          return item;
        }
      } else {
        if (checkPermission(item.role, item.accessRole, permissions)) {
          item.single = true;
        }
      }
    });
  } else {
    console.log("not fetch!!!!!!!");
  }

  const filterSideBar = (e) => {
    const filterText = e.target.value;
    console.log("value is ", filterText);
    SideBarData.map((data) => (data.openMenu = false));

    const final = [];
    const filterdResult = SideBarData.filter((item) => {
      if (item.child?.length) {
        const childFilter = item.child.filter((chiledItem) =>
          chiledItem.title.includes(filterText)
        );
        console.log("my child filter", childFilter);
        if (childFilter.length) {
          final.push({ ...item, child: childFilter });
        } else {
          return false;
        }
      } else {
        return item.title.includes(filterText) && final.push(item);
      }
    });
    console.log("filtered result is ", filterdResult);
    setSideBar(final.length > 0 ? final : SideBarData);
  };

  return token && permissions ? (
    <SideBarWrapper
      style={
        widthSidebar === "f1"
          ? {
              transform: "translateX(0)",
              transition: "all 1s",
              display: "block",
            }
          : {
              transform: "translateX(275px)",
              transition: "all 1s",
              display: "none",
            }
      }
      sx={{ bgcolor: menuColor }}
      width={250}
    >
      <SideBarList
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListItem
            title={widthSidebar === "f1" ? "Main Menu" : ""}
            icon={<MainMenuIcon />}
            headerListItem
          />
        }
      >
        <input
          type="text"
          placeholder="search"
          onChange={filterSideBar}
          style={{
            width: "95%",
            height: "30px",
            margin: "0 2px",
          }}
        />
        {sideBar.map((sideBarItem, index) =>
          // menus list
          sideBarItem.child?.filter((child) => child !== null).length > 0 ||
          sideBarItem.single ? (
            <ListItem
              menuColor={menuColor}
              subMenuColor={subMenuColor}
              key={index}
              title={widthSidebar === "f1" ? sideBarItem.title : ""}
              icon={sideBarItem.icon}
              child={sideBarItem.child}
              parentpath={sideBarItem.path}
              openMenu={sideBarItem.openMenu}
            />
          ) : undefined
        )}
      </SideBarList>
    </SideBarWrapper>
  ) : (
    <></>
  );
}
NestedList.propTypes = {
  menuColor: proptypes.string,
  subMenuColor: proptypes.string,
  widthSidebar: proptypes.bool,
  permissions: proptypes.array,
};
