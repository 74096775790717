import { secondaryColor } from "../../../colors/allColors.color";
import { styled, Box, Avatar } from "@mui/material";

export const StatusProfWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "205px",
  height: "40px",
  justifyContent: "space-between",
  color: secondaryColor,
}));

export const UserAvatar = styled(Avatar)(() => ({
  height: "40px",
  width: "40px",
}));
