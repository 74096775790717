import { lazy } from "react";

const Home = lazy(() => import("../Pages/Home/home.page"));
const Help = lazy(() => import("../Pages/Home/help.page"));

/**** car ****/
const Agents = lazy(() => import("../Pages/ListAgents/Agents.page"));
const AgentsChanges = lazy(() =>
  import("../Pages/ListAgents/AgentsChanges.page")
);
const RegisterAgent = lazy(() =>
  import("../Pages/RegisterAgent/RegisterAgent.page")
);
const RegisterAgentCarOwner = lazy(() =>
  import("../Pages/RegisterAgent/RegisterAgentCarOwner2.page")
);

const EditionAgent = lazy(() =>
  import("../Pages/RegisterAgent/EditionAgent.page")
);
const ShowAgent = lazy(() => import("../Pages/RegisterAgent/ShowAgent.page"));
const ShowAgentChanges = lazy(() =>
  import("../Pages/RegisterAgent/ShowAgentChanges.page")
);
/*** car owner ***/
const CarOwners = lazy(() => import("../Pages/ListCarOwners/CarOwners.page"));
const RegisterCarOwner = lazy(() =>
  import("../Pages/RegisterCarOwner/RegisterCarOwner.page")
);
const RegisterCarOwnerAdmin = lazy(() =>
  import("../Pages/RegisterCarOwner/RegisterCarOwnerAdmin.page")
);
const ListChangeCarOwners = lazy(() =>
  import("../Pages/ListCarOwners/CarOwnersChanges.page")
);
const EditionCarOwner = lazy(() =>
  import("../Pages/RegisterCarOwner/EditionCarOwnerAdmin.page")
);
const EditionCarOwnerBySelf = lazy(() =>
  import("../Pages/RegisterCarOwner/EditionCarOwnerBySelf.page")
);
const ShowCarOwner = lazy(() =>
  import("../Pages/RegisterCarOwner/ShowCarOwner.page")
);
const ShowCarOwnerChanges = lazy(() =>
  import("../Pages/RegisterCarOwner/ShowCarOwnerChanges.page")
);
const CarsList = lazy(() => import("../Pages/ListCars/carsList.page"));
const deletedCarsOwnerList = lazy(() =>
  import("../Pages/ListCars/deletedCarsOwnerList.page")
);
const deletedCarsList = lazy(() =>
  import("../Pages/ListCars/deletedCarsList.page")
);

const CarsOwnerList = lazy(() =>
  import("../Pages/ListCars/carsOwnerList.page")
);
const CarsChanges = lazy(() => import("../Pages/ListCars/carsChanges.page"));
const RegisterCar = lazy(() =>
  import("../Pages/RegisterCar/RegisterCarByAdmin.page")
);
const EditionCarByAdmin = lazy(() =>
  import("../Pages/RegisterCar/EditionCarByAdmin.page")
);
const ChangePasswordCar = lazy(() =>
  import("../Pages/RegisterCar/ChangePasswordCar.page")
);
const CarDetails = lazy(() => import("../Pages/RegisterCar/SeeCar.page"));
const SeeCarCarOwner = lazy(() =>
  import("../Pages/RegisterCar/SeeCarCarowner.page")
);
const SeeCarChanges = lazy(() =>
  import("../Pages/RegisterCar/SeeCarChanges.page")
);

/**Cars list2**/
const ShowActiveUser = lazy(() =>
  import("../Pages/ActiveUserInApp/activeUserInApp.page")
);

/*** travel Passenger ****/
const PassengerTravelsList = lazy(() =>
  import("../Pages/ListPassengerTravels/PassengerTravelsList.page")
);
const PassengerCarTravelsList = lazy(() =>
  import("../Pages/ListPassengerTravels/PassengerCarTravelsList.page")
);
const SeePassengerTravel = lazy(() =>
  import("../Pages/ListPassengerTravels/seePassengerTravel.page")
);

/*** current trip ***/
const CurrentTravelsList = lazy(() =>
  import("../Pages/ListPassengerTravels/CurrentTravelsList.page")
);
/**** travel Driver ***/
const DriverTravelsList = lazy(() =>
  import("../Pages/ListDriverTravels/DriverTravelsList.page")
);
const DriverTravelsByRouteList = lazy(() =>
  import("../Pages/ListDriverTravels/DriverTravelsByRouteList.page")
);
const DriverTravelsSpecialList = lazy(() =>
  import("../Pages/ListDriverTravels/DriverTravelsSpecialList.page")
);

const SeeDriverTravel = lazy(() =>
  import("../Pages/RegisterDriverTrip/seeDriverTravel.page")
);
const EditDriverTravel = lazy(() =>
  import("../Pages/RegisterDriverTrip/EditDriverTravel.page")
);
const CodeDriverTravel = lazy(() =>
  import("../Pages/RegisterDriverTrip/CodeDriverTravel.page")
);

/** Reports **/

const BlockedAdmin = lazy(() =>
  import("../Pages/BlockedAdmin/BlockedAdmin.page")
);
const BlockedCarOwner = lazy(() =>
  import("../Pages/BlockedAdmin/BlockedCarOwner.page")
);

/** Finance Report **/
const MonthlyExpenseReport = lazy(() =>
  import("../Pages/MonthlyExpenceReport/MonthlyExpenseReport.page")
);
const MonthlyIncomeReport = lazy(() =>
  import("../Pages/MonthlyIncomeReport/MonthlyIncomeReport.page")
);

/*** blocked admin ***/

/** Everyday Access **/
const MonthlyReport = lazy(() =>
  import("../Pages/MonthlyReport/MonthlyReport.page")
);
const MonthlyReportCarOwner = lazy(() =>
  import("../Pages/MonthlyReport/MonthlyReportCarOwner.page")
);
const MonthlyReportDriver = lazy(() =>
  import("../Pages/MonthlyReport/MonthlyReportDriver.page")
);

/*** point travel ****/
const PointTravelsList = lazy(() =>
  import("../Pages/ListPointTravel/PointTravelsList.page")
);
const PointTravelsListById = lazy(() =>
  import("../Pages/ListPointTravel/PointTravelsListById.page")
);
const SeePointTravel = lazy(() =>
  import("../Pages/ListPointTravel/seePointTravel.page")
);
/*** drivers ***/
const Drivers = lazy(() => import("../Pages/ListDrivers/driversList.page"));
const DriversSingleList = lazy(() =>
  import("../Pages/ListDrivers/driversSingleList.page")
);

const CarOwnerDriversList = lazy(() =>
  import("../Pages/ListDrivers/carOwnerDriversList.page")
);
const CarOwnerDriversListById = lazy(() =>
  import("../Pages/ListDrivers/carOwnerDriversListById.page")
);
const carOwnerSmallNewDriversList = lazy(() =>
  import("../Pages/ListDrivers/carOwnerSmallNewDriversList.page")
);
const DriversChanges = lazy(() =>
  import("../Pages/ListDrivers/driverChanges.page")
);
const DriverConnectPlaque = lazy(() =>
  import("../Pages/ListDrivers/driverConnectPlaque.page")
);
const DriverRejectTrips = lazy(() =>
  import("../Pages/ListDrivers/driverRejectTrips.page")
);
const AddDrivers = lazy(() => import("../Pages/RegisterDrivers/add.page"));
const EditDrivers = lazy(() => import("../Pages/RegisterDrivers/edit.page"));
const ShowDriver = lazy(() => import("../Pages/RegisterDrivers/show.page"));
const showChangesDriver = lazy(() =>
  import("../Pages/RegisterDrivers/showChanges.page")
);

const MonthlyPoint = lazy(() =>
  import("../Pages/ListDrivers/monthlyPoint.page")
);
const YearlyPoint = lazy(() => import("../Pages/ListDrivers/yearlyPoint.page"));
const YearlyPointPassenger = lazy(() =>
  import("../Pages/ListPassenger/yearlyPoint.page")
);
const MonthlyPointPassenger = lazy(() =>
  import("../Pages/ListPassenger/monthlyPoint.page")
);
const MonthlyFullPoint = lazy(() =>
  import("../Pages/ListDrivers/monthlyFullPoint.page")
);
const YearlyFullPoint = lazy(() =>
  import("../Pages/ListDrivers/yearlyFullPoint.page")
);
const RegisterDrivers = lazy(() => import("../Pages/RegisterDrivers/add.page"));

/**** action drivers ****/

/**** access drivers ****/
const AccessDrivers = lazy(() =>
  import("../Pages/ListAccessDrivers/accessDriversList.page")
);
const ChangeAccessDrivers = lazy(() =>
  import("../Pages/ListAccessDrivers/changeAccessDrivers.page")
);

/**** add drivers car ****/
const AddDiversToCar = lazy(() =>
  import("../Pages/ListAccessDrivers/addDriversToCar.page")
);

/**** total travel and trip ****/
const TotalTravelAndTrip = lazy(() =>
  import("../Pages/ListTotalTravelAndTrip/totalTravelAndTrip.page")
);

/*** reports ***/
const PassengerOnMap = lazy(() =>
  import("../Pages/ListPassengerOnMap/passengerOnMap.page")
);
const DriverOnMap = lazy(() =>
  import("../Pages/ListPassengerOnMap/DriverOnMap.page")
);

const MotionGraphicsList = lazy(() =>
  import("../Pages/ListMotionGraphics/motionGraphicsList.page")
);
const RegisterMotionGraphic = lazy(() =>
  import("../Pages/RegisterMotionGraphic/RegisterMotionGraphic.page")
);
const EditionMotionGraphic = lazy(() =>
  import("../Pages/RegisterMotionGraphic/EditionMotionGraphic.page")
);
const ShowMotionGraphic = lazy(() =>
  import("../Pages/RegisterMotionGraphic/ShowMotionGraphic.page")
);

/*** safety shield ***/
const SafetyShieldList = lazy(() =>
  import("../Pages/ListSafetyShield/SafetyShieldList.page")
);

const SafetyShieldLogsList = lazy(() =>
  import("../Pages/ListSafetyShield/SafetyShieldLogsList.page")
);

const SafetyShield = lazy(() =>
  import("../Pages/ListSafetyShield/SafetyShield.page")
);
/**** wallet ****/
const RequestPayList = lazy(() =>
  import("../Pages/ListWallet/RequestPayList.page")
);
const Wallet = lazy(() => import("../Pages/ListWallet/wallet.page"));
const walletById = lazy(() => import("../Pages/ListWallet/walletById.page"));
const RegisterWallet = lazy(() =>
  import("../Pages/RegisterWallet/registerWallet.page")
);
const ScoreBagInternalUser = lazy(() =>
  import("../Pages/ListWallet/ScoreBagInternalUser.page")
);
const ScoreBagInternalUserAdmin = lazy(() =>
  import("../Pages/ListWallet/ScoreBagInternalUserAdmin.page")
);
const Microtransactions = lazy(() =>
  import("../Pages/ListWallet/Microtransactions.page")
);
const MyMicrotransactions = lazy(() =>
  import("../Pages/ListWallet/MyMicrotransactions.page")
);
const AdminMicrotransactions = lazy(() =>
  import("../Pages/ListWallet/AdminMicrotransactions.page")
);
const MicrotransactionsSingle = lazy(() =>
  import("../Pages/ListWallet/MicrotransactionsSingle.page")
);
const MicrotransactionsSingleWithoutFilter = lazy(() =>
  import("../Pages/ListWallet/MicrotransactionsSingleWithoutFilter.page")
);
const RegisterTransAction = lazy(() =>
  import("../Pages/RegisterWallet/registerTransAction.page")
);
const MicrotransactionsMonthly = lazy(() =>
  import("../Pages/ListWallet/MicrotransactionsMonthly.page")
);

const RegisterRequestPay = lazy(() =>
  import("../Pages/RegisterWallet/registerRequestPay.page")
);
const RegisterRequestPaySingle = lazy(() =>
  import("../Pages/RegisterWallet/registerRequestPaySingle.page")
);
/*** list sms ***/
const Sms = lazy(() => import("../Pages/ListSms/sms.page"));
const SmsCarOwner = lazy(() => import("../Pages/ListSms/SmsCarowner.page"));
/*** list user notes ***/
const UserNotes = lazy(() => import("../Pages/ListUserNotes/userNotes.page"));
const RegisterNote = lazy(() =>
  import("../Pages/RegisterNote/registerNote.page")
);
const EditNote = lazy(() => import("../Pages/RegisterNote/editNote.page"));
const SeeNote = lazy(() => import("../Pages/RegisterNote/seeNote.page"));
/**** support requests ****/
const SupportRequest = lazy(() =>
  import("../Pages/ListSupportRequest/supportRequest.page")
);
const supportRequestCarOwner = lazy(() =>
  import("../Pages/ListSupportRequest/supportRequestCarOwner.page")
);
/**** add support request ****/
const AddSupportRequest = lazy(() =>
  import("../Pages/supportRequestRoute/SupprotRequestRoute.page")
);
const AddSupportRequestCarOwner = lazy(() =>
  import("../Pages/supportRequestRoute/SupportRequestRouteCarOwner.page")
);
const SupportRequestRouteCarOwnerShow = lazy(() =>
  import("../Pages/supportRequestRoute/SupportRequestRouteCarOwnerShow.page")
);
//Support request edit
const EditSupportRequest = lazy(() =>
  import("../Pages/supportRequestRoute/SupportRequestEdit.page")
);
/**** section 2 ****/
const EditionUser = lazy(() =>
  import("../Pages/RegisterUser/EditionUser.page")
);

/***** settings ****/
const RegisterSetting = lazy(() =>
  import("../Pages/RegisterSetting/RegisterSetting.page")
);

/*** Admins ***/
const AdminsList = lazy(() => import("../Pages/ListAdmins/Admins.page"));
const LastChangesList = lazy(() =>
  import("../Pages/ListAdmins/LastChangesList.page")
);
const LastChangesUsersList = lazy(() =>
  import("../Pages/ListAdmins/LastChangesUsersList.page")
);
const RegisterAdmin = lazy(() =>
  import("../Pages/RegisterAdmin/RegisterAdmin.page")
);
const EditionAdmin = lazy(() =>
  import("../Pages/RegisterAdmin/EditionAdmin.page")
);
const ShowAdmin = lazy(() => import("../Pages/RegisterAdmin/ShowAdmin.page"));

/*** Companies ***/
const Companies = lazy(() => import("../Pages/ListCompanies/Companies.page"));
const RegisterCompany = lazy(() =>
  import("../Pages/RegisterCompany/RegisterCompany.page")
);
const EditionCompany = lazy(() =>
  import("../Pages/RegisterCompany/EditionCompany.page")
);
const ShowCompany = lazy(() =>
  import("../Pages/RegisterCompany/ShowCompany.page")
);

/*** origin and destionation ***/
const OriginAndDestinations = lazy(() =>
  import("../Pages/ListOriginAndDestinations/originAndDestinations.page")
);
const RegisterCityAndStation = lazy(() =>
  import("../Pages/RegisterCityAndStation/RegisterCityAndStation.page")
);
const EditionCityAndStation = lazy(() =>
  import("../Pages/RegisterCityAndStation/EditionCityAndStation.page")
);

/**** routes ****/
const Routes = lazy(() => import("../Pages/ListRoutes/Routes.page"));
const RegisterRoute = lazy(() =>
  import("../Pages/RegisterRoutes/RegisterRoute.page")
);
const EditionRoute = lazy(() =>
  import("../Pages/RegisterRoutes/EditionRoute.page")
);
const ShowRoute = lazy(() => import("../Pages/RegisterRoutes/ShowRoute.page"));

/********** Route A *********/
const InterRouteA = lazy(() =>
  import("../Pages/ListInterRouteA/InterRouteA.page")
);
const RegisterInterRouteA = lazy(() =>
  import("../Pages/RegisterInterRouteA/RegisterInterRouteA.page")
);
const EditionInterRouteA = lazy(() =>
  import("../Pages/RegisterInterRouteA/EditionInterRouteA.page")
);
const ShowInterRouteA = lazy(() =>
  import("../Pages/RegisterInterRouteA/ShowInterRouteA.page")
);
/***** Route B ******/
const FareIncreaseRouteA2 = lazy(() =>
  import("../Pages/ListFareIncreaseRouteA2/FareIncreaseRouteA2.page")
);
const RegisterFareIncreaseRouteA2 = lazy(() =>
  import(
    "../Pages/RegisterFareIncreaseRouteA2/RegisterFareIncreaseRouteA2.page"
  )
);
const EditionFareIncreaseRouteA2 = lazy(() =>
  import("../Pages/RegisterFareIncreaseRouteA2/EditionFareIncreaseRouteA2.page")
);
const ShowFareIncreaseRouteA2 = lazy(() =>
  import("../Pages/RegisterFareIncreaseRouteA2/ShowFareIncreaseRouteA2.page")
);

const ListTotalCarsAndTrip = lazy(() =>
  import("../Pages/ListTotalCarsAndTrip/ListTotalCarsAndTrip.page")
);

const SeparateCars = lazy(() =>
  import("../Pages/ListSeparateCars/SeparateCars.page")
);

/**** category support ****/
const CategorySupport = lazy(() =>
  import("../Pages/ListCategorySupport/CategorySupport.page")
);
const RegisterCategorySupport = lazy(() =>
  import("../Pages/RegisterCategorySupport/RegisterCategorySupport.page")
);
const EditionCategorySupport = lazy(() =>
  import("../Pages/RegisterCategorySupport/EditionCategorySupport.page")
);
const ShowCategorySupport = lazy(() =>
  import("../Pages/RegisterCategorySupport/ShowCategorySupport.page")
);

/**** Commission coefficient ***/
const CommissionCoefficient = lazy(() =>
  import("../Pages/ListCommissionCoefficient/CommissionCoefficient.page")
);
const RegisterCommissionCoefficient = lazy(() =>
  import(
    "../Pages/RegisterCommissionCoefficient/RegisterCommissionCoefficient.page"
  )
);

const EditionCommissionCoefficient = lazy(() =>
  import(
    "../Pages/RegisterCommissionCoefficient/EditionCommissionCoefficient.page"
  )
);
const ShowCommissionCoefficient = lazy(() =>
  import(
    "../Pages/RegisterCommissionCoefficient/ShowCommissionCoefficient.page"
  )
);

/*** commission driver ***/

const CommissionDriver = lazy(() =>
  import("../Pages/ListCommissionDriver/CommissionDriver.page")
);
const RegisterCommissionDriver = lazy(() =>
  import("../Pages/RegisterCommissionDriver/RegisterCommissionDriver.page")
);

const SeeCommissionDriver = lazy(() =>
  import("../Pages/RegisterCommissionDriver/SeeCommissionDriver.page")
);

/**** send sms ****/
const RegisterSendSms = lazy(() =>
  import("../Pages/RegisterSendSms/RegisterSendSms.page")
);
const ShowSendSms = lazy(() =>
  import("../Pages/RegisterSendSms/ShowSendSms.page")
);

/*** Secretariat categories ****/
const SecretariatCategories = lazy(() =>
  import("../Pages/ListSecretariatCategory/SecretariatCategories.page")
);

const RegisterSecretariatCategory = lazy(() =>
  import(
    "../Pages/RegisterSecretariatCategory/RegisterSecretariatCategory.page"
  )
);

const EditSecretariatCategory = lazy(() =>
  import("../Pages/RegisterSecretariatCategory/EditSecretariatCategory.page")
);
const ShowSecretariatCategory = lazy(() =>
  import("../Pages/RegisterSecretariatCategory/ShowSecretariatCategory.page")
);

/**** departed Secretariat ***/
const ListDeparted = lazy(() => import("../Pages/ListDeparted/Departed.page"));
const RegisterDepartedSecretariat = lazy(() =>
  import(
    "../Pages/RegisterDepartedSecretariat/RegisterDepartedSecretariat.page"
  )
);

const ShowDepartedSecretariat = lazy(() =>
  import("../Pages/RegisterDepartedSecretariat/ShowDepartedSecretariat.page")
);
const EditDepartedSecretariat = lazy(() =>
  import("../Pages/RegisterDepartedSecretariat/EditDepartedSecretariat.page")
);
const EditDepartedReceived = lazy(() =>
  import("../Pages/RegisterDepartedSecretariat/EditDepartedReceived.page")
);

const ShowDepartedReceived = lazy(() =>
  import("../Pages/RegisterDepartedSecretariat/ShowDepartedReceived.page")
);

/*** received ***/
const DepartedReceived = lazy(() =>
  import("../Pages/ListDeparted/DepartedReceived.page")
);
const RegisterDepartedReceived = lazy(() =>
  import("../Pages/RegisterDepartedSecretariat/RegisterDepartedReceived.page")
);
/*** support history ***/
const SupportHistory = lazy(() =>
  import("../Pages/ListSupportRequest/supportHistory.page")
);

/**** ticket categories ****/
const TicketCategories = lazy(() =>
  import("../Pages/ListTicketCategory/TicketCategories.page")
);

const RegisterTicketCategory = lazy(() =>
  import("../Pages/RegisterTicketCategory/RegisterTicketCategory.page")
);
const EditTicketCategory = lazy(() =>
  import("../Pages/RegisterTicketCategory/EditTicketCategory.page")
);

const ShowTicketCategory = lazy(() =>
  import("../Pages/RegisterTicketCategory/ShowTicketCategory.page")
);

/**** send ticket ****/

const SeeTickets = lazy(() => import("../Pages/ListTickets/seeTickets.page"));
const Tickets = lazy(() => import("../Pages/ListTickets/tickets.page"));
const NotCheckedTickets = lazy(() =>
  import("../Pages/ListTickets/notCheckedTickets.page")
);
const LogTickets = lazy(() => import("../Pages/ListTickets/logTickets.page"));
const RegisterTicket = lazy(() =>
  import("../Pages/RegisterTicket/RegisterTicket.page")
);
const RegisterTicketAgent = lazy(() =>
  import("../Pages/RegisterTicket/RegisterTicketAgent.page")
);
const EditTicket = lazy(() =>
  import("../Pages/RegisterTicket/EditTicket.page")
);
const EditTicketAgent = lazy(() =>
  import("../Pages/RegisterTicket/EditTicketAgent.page")
);
const ShowTicket = lazy(() =>
  import("../Pages/RegisterTicket/ShowTicket.page")
);
const ShowFullTicket = lazy(() =>
  import("../Pages/RegisterTicket/ShowFullTicket.page")
);

/*** SuperintendentsReport ***/

/***** user groups *****/
const UserGroups = lazy(() => import("../Pages/ListUserGroup/UserGroups.page"));
const RegisterUserGroup = lazy(() =>
  import("../Pages/RegisterUserGroups/RegisterUserGroup.page")
);
const EditionUserGroup = lazy(() =>
  import("../Pages/RegisterUserGroups/EditionUserGroup.page")
);
const ShowUserGroup = lazy(() =>
  import("../Pages/RegisterUserGroups/ShowUserGroup.page")
);

/***** notifications *****/
const ListNotifications = lazy(() =>
  import("../Pages/ListNotification/Notifications.page")
);

const RegisterNotification = lazy(() =>
  import("../Pages/RegisterSendSms/RegisterNotification.page")
);

const ShowNotification = lazy(() =>
  import("../Pages/RegisterSendSms/ShowNotification.page")
);

/**** list passsenger ****/
const Passenger = lazy(() => import("../Pages/ListPassenger/Passenger.page"));
const ArchivePassenger = lazy(() =>
  import("../Pages/ListPassenger/ArchivePassenger.page")
);
const RegisterPassenger = lazy(() =>
  import("../Pages/RegisterPassenger/RegisterPassenger.page")
);
const EditionPassenger = lazy(() =>
  import("../Pages/RegisterPassenger/EditionPassenger.page")
);
const ShowPassenger = lazy(() =>
  import("../Pages/RegisterPassenger/ShowPassenger.page")
);

/** orders**/
const OrdersList = lazy(() => import("../Pages/ListOrders/orders.page"));
const OrdersByPassengerId = lazy(() =>
  import("../Pages/ListOrders/ordersByPassengerId.page")
);
const ListOffers = lazy(() => import("../Pages/ListOrders/listOffers.page"));
const ListOffersById = lazy(() =>
  import("../Pages/ListOrders/listOffersById.page")
);
const ArchiveOrdersList = lazy(() =>
  import("../Pages/ListOrders/archiveOrders.page")
);

const SeeOrder = lazy(() => import("../Pages/ListOrders/seeOrder.page"));

/** Stopping time **/
const StoppingTime = lazy(() => import("../Pages/StoppingTime/list.page"));
const AddStoppingTime = lazy(() => import("../Pages/StoppingTime/add.page"));
const EditStoppingTime = lazy(() => import("../Pages/StoppingTime/edit.page"));
const SeeStoppingTime = lazy(() => import("../Pages/StoppingTime/see.page"));

/** Reward setting **/
const RewardSetting = lazy(() => import("../Pages/RewardSetting/add.page"));
const EditRewardSetting = lazy(() =>
  import("../Pages/RewardSetting/edit.page")
);
const DriverFine = lazy(() => import("../Pages/DriverFine/DriverFine.page"));
const EditionDriverFine = lazy(() =>
  import("../Pages/DriverFine/EditionDriverFine.page")
);

/**  routes **/
const RegisterAccessCar = lazy(() =>
  import("../Pages/RegisterAccessCar/RegisterAccessCar.page")
);

const SpecialRouteCarOwnerList = lazy(() =>
  import("../Pages/ListSpecialRoute/specialRouteCarOwnerList.page")
);
const specialRouteCompanyList = lazy(() =>
  import("../Pages/ListSpecialRoute/specialRouteCompanyList.page")
);
const TotalCarAndTripList = lazy(() =>
  import("../Pages/ListSpecialRoute/totalCarAndTripList.page")
);

// my notification
const Mynotification = lazy(() => import("../Pages/my-notification"));

const Loginlogoutlog = lazy(() => import("../Pages/login-logout-log"));
const Offer = lazy(() => import("../Pages/ListOrders/offer.page"));

export const allRoutes = [
  {
    path: "/login-logout-log/:id?",
    component: Loginlogoutlog,
    idx: true,
  },
  {
    path: "/offer/:id",
    component: Offer,
    idx: true,
  },
  {
    path: "/dashboard/:type",
    component: Home,
    idx: true,
  },
  {
    path: "/help",
    component: Help,
    idx: true,
  },
  {
    path: "/agents",
    component: Agents,
    idx: true,
  },
  {
    path: "/agent-changes/:id",
    component: AgentsChanges,
    idx: true,
  },
  {
    path: "/add-agent",
    component: RegisterAgent,
    idx: true,
  },
  {
    path: "/add-agent-carowner",
    component: RegisterAgentCarOwner,
    idx: true,
  },
  {
    path: "/edit-agent/:id",
    component: EditionAgent,
    idx: true,
  },
  {
    path: "/agent-details/:id",
    component: ShowAgent,
    idx: true,
  },
  {
    path: "/agent-changes-details/:id",
    component: ShowAgentChanges,
    idx: true,
  },
  {
    path: "/car-owners",
    component: CarOwners,
    idx: true,
  },
  {
    path: "/car-owner-changes/:id",
    component: ListChangeCarOwners,
    idx: true,
  },
  {
    path: "/car-owner-changes-details/:id",
    component: ShowCarOwnerChanges,
    idx: true,
  },
  {
    path: "/add-car-owner",
    component: RegisterCarOwner,
    idx: true,
  },
  {
    path: "/add-car-owner-by-admin",
    component: RegisterCarOwnerAdmin,
    idx: true,
  },
  {
    path: "/edit-car-owner-by-admin/:id",
    component: EditionCarOwner,
    idx: true,
  },
  {
    path: "/edit-carowner-by-self",
    component: EditionCarOwnerBySelf,
    idx: true,
  },
  {
    path: "/car-owner-by-admin-details/:id",
    component: ShowCarOwner,
    idx: true,
  },
  {
    path: "/cars",
    component: CarsList,
    idx: true,
  },
  {
    path: "/deleted-cars",
    component: deletedCarsList,
    idx: true,
  },
  {
    path: "/deleted-cars-owner",
    component: deletedCarsOwnerList,
    idx: true,
  },
  {
    path: "/cars-owner",
    component: CarsOwnerList,
    idx: true,
  },
  {
    path: "/car-changes/:id",
    component: CarsChanges,
    idx: true,
  },

  {
    path: "/cars-list2/active-user/:id",
    component: ShowActiveUser,
    idx: true,
  },
  {
    path: "/add-car/:type",
    component: RegisterCar,
    idx: true,
  },
  {
    path: "/edit-car-by-admin/:id",
    component: EditionCarByAdmin,
    idx: true,
  },
  {
    path: "/change-password-car/:id",
    component: ChangePasswordCar,
    idx: true,
  },
  {
    path: "/detail-car/:id",
    component: CarDetails,
    idx: true,
  },
  {
    path: "/detail-car-carowner/:id",
    component: SeeCarCarOwner,
    idx: true,
  },
  {
    path: "/car-changes-details/:id",
    component: SeeCarChanges,
    idx: true,
  },
  {
    path: "/passenger-travels-list/:city?",
    component: PassengerTravelsList,
    idx: true,
  },
  {
    path: "/passenger-car-travels-list/:city",
    component: PassengerCarTravelsList,
    idx: true,
  },
  {
    path: "/see-passenger-travel/:id",
    component: SeePassengerTravel,
    idx: true,
  },
  {
    path: "/driver-travels-list",
    component: DriverTravelsList,
    idx: true,
  },
  {
    path: "/driver-travels-list-by-route/:city?",
    component: DriverTravelsByRouteList,
    idx: true,
  },
  {
    path: "/driver-travels-list/:id",
    component: DriverTravelsList,
    idx: true,
  },
  {
    path: "/driver-travels-special-list/:service?/:origin?/:destination?/:owner?",
    component: DriverTravelsSpecialList,
    idx: true,
  },
  {
    path: "/status-driver-current-trip",
    component: CurrentTravelsList,
    idx: true,
  },
  {
    path: "/edit-driver-travel/:id",
    component: EditDriverTravel,
    idx: true,
  },
  {
    path: "/see-driver-travel/:id",
    component: SeeDriverTravel,
    idx: true,
  },
  {
    path: "/point-travels-list",
    component: PointTravelsList,
    idx: true,
  },
  {
    path: "/point-travels-list/:id",
    component: PointTravelsListById,
    idx: true,
  },
  {
    path: "/see-point-travel/:id",
    component: SeePointTravel,
    idx: true,
  },
  {
    path: "/drivers/:id?",
    component: Drivers,
    idx: true,
  },
  {
    path: "/drivers-single/:id",
    component: DriversSingleList,
    idx: true,
  },
  {
    path: "/carowner-drivers",
    component: CarOwnerDriversList,
    idx: true,
  },
  {
    path: "/carowner-drivers/:id",
    component: CarOwnerDriversListById,
    idx: true,
  },
  {
    path: "/carowner-new-drivers",
    component: carOwnerSmallNewDriversList,
    idx: true,
  },
  {
    path: "/driver-changes/:id",
    component: DriversChanges,
    idx: true,
  },
  {
    path: "/driver-connect-plaque/:id",
    component: DriverConnectPlaque,
    idx: true,
  },
  {
    path: "/driver-reject-trips/:id",
    component: DriverRejectTrips,
    idx: true,
  },
  {
    path: "/add-driver",
    component: AddDrivers,
    idx: true,
  },
  {
    path: "/edit-driver/:id",
    component: EditDrivers,
    idx: true,
  },
  {
    path: "/driver-details/:id",
    component: ShowDriver,
    idx: true,
  },
  {
    path: "/driver-auth/verify-code/:id",
    component: CodeDriverTravel,
    idx: true,
  },
  {
    path: "/show-changes-driver/:id",
    component: showChangesDriver,
    idx: true,
  },
  {
    path: "/add-drivers",
    component: RegisterDrivers,
    idx: true,
  },
  {
    path: "/edit-drivers/:id",
    component: RegisterDrivers,
    idx: true,
  },

  {
    path: "/drivers/monthly-point/:id?",
    component: MonthlyPoint,
    idx: true,
  },
  {
    path: "/drivers/yearly-point/:id?",
    component: YearlyPoint,
    idx: true,
  },
  {
    path: "/drivers-and-passenger/monthly-point",
    component: MonthlyFullPoint,
    idx: true,
  },
  {
    path: "/drivers-and-passenger/yearly-point",
    component: YearlyFullPoint,
    idx: true,
  },

  {
    path: "/passenger/monthly-point/:id?",
    component: MonthlyPointPassenger,
    idx: true,
  },
  {
    path: "/passenger/yearly-point/:id?",
    component: YearlyPointPassenger,
    idx: true,
  },

  {
    path: "/access-drivers/:id",
    component: AccessDrivers,
    idx: true,
  },
  {
    path: "/change-access-drivers/:id",
    component: ChangeAccessDrivers,
    idx: true,
  },
  {
    path: "/add-drivers-car/:id",
    component: AddDiversToCar,
    idx: true,
  },
  {
    path: "/total-travel-and-trip/:deleted/:id?",
    component: TotalTravelAndTrip,
    idx: true,
  },
  {
    path: "/wallet/:type",
    component: Wallet,
    idx: true,
  },
  {
    path: "/wallet/car/:id",
    component: walletById,
    idx: true,
  },
  {
    path: "/increase-wallet",
    component: RegisterWallet,
    idx: true,
  },
  {
    path: "/score-bag",
    component: ScoreBagInternalUser,
    idx: true,
  },
  {
    path: "/score-bag-admin/:id?",
    component: ScoreBagInternalUserAdmin,
    idx: true,
  },
  {
    path: "/microtransactions/:type?/:id?",
    component: Microtransactions,
    idx: true,
  },
  {
    path: "/admin-microtransactions",
    component: AdminMicrotransactions,
    idx: true,
  },
  {
    path: "/my-microtransactions",
    component: MyMicrotransactions,
    idx: true,
  },
  {
    path: "/microtransactions-driver",
    component: MicrotransactionsSingle,
    idx: true,
  },
  {
    path: "/microtransactions-carowner",
    component: MicrotransactionsSingleWithoutFilter,
    idx: true,
  },
  {
    path: "/add-transactions/:type/:id",
    component: RegisterTransAction,
    idx: true,
  },
  {
    path: "/request-pays",
    component: RequestPayList,
    idx: true,
  },
  {
    path: "/request-pay/:type/:id",
    component: RegisterRequestPay,
    idx: true,
  },
  {
    path: "/request-pay-self/:type",
    component: RegisterRequestPaySingle,
    idx: true,
  },
  {
    path: "/microtransactions",
    component: MicrotransactionsMonthly,
    idx: true,
  },
  {
    path: "/user-notes",
    component: UserNotes,
    idx: true,
  },
  {
    path: "/add-note",
    component: RegisterNote,
    idx: true,
  },
  {
    path: "/edit-note/:id",
    component: EditNote,
    idx: true,
  },
  {
    path: "/see-note/:id",
    component: SeeNote,
    idx: true,
  },
  {
    path: "/support-requests",
    component: SupportRequest,
    idx: true,
  },
  {
    path: "/support-requests-by-car-owner",
    component: supportRequestCarOwner,
    idx: true,
  },
  {
    path: "/support-requests-by-car-owner-add",
    component: AddSupportRequestCarOwner,
    idx: true,
  },
  {
    path: "/support-requests-by-car-owner-show/:id",
    component: SupportRequestRouteCarOwnerShow,
    idx: true,
  },
  {
    path: "/support-requests-add",
    component: AddSupportRequest,
    idx: true,
  },
  {
    path: "/support-requests-edit/:id",
    component: EditSupportRequest,
    idx: true,
  },

  /****** section 2 *****/
  {
    path: "/my-profile",
    component: EditionUser,
    idx: true,
  },
  /**** setting ****/
  {
    path: "/main-setting",
    component: RegisterSetting,
    idx: true,
  },
  /*** admins ***/
  {
    path: "/admins",
    component: AdminsList,
    idx: true,
  },
  {
    path: "/last-changes/:id?",
    component: LastChangesList,
    idx: true,
  },
  {
    path: "/last-changes-users/:id?",
    component: LastChangesUsersList,
    idx: true,
  },
  {
    path: "/add-admin",
    component: RegisterAdmin,
    idx: true,
  },
  {
    path: "/edit-admin/:id",
    component: EditionAdmin,
    idx: true,
  },
  {
    path: "/admin-details/:id",
    component: ShowAdmin,
    idx: true,
  },
  /**** companies ****/
  {
    path: "/companies",
    component: Companies,
    idx: true,
  },
  {
    path: "/add-company",
    component: RegisterCompany,
    idx: true,
  },
  {
    path: "/edit-company/:id",
    component: EditionCompany,
    idx: true,
  },
  {
    path: "/see-company/:id",
    component: ShowCompany,
    idx: true,
  },

  /**** origin and destionation ****/
  {
    path: "/origin-and-destinations",
    component: OriginAndDestinations,
    idx: true,
  },
  {
    path: "/add-city-and-station",
    component: RegisterCityAndStation,
    idx: true,
  },
  {
    path: "/edit-city-and-station/:id",
    component: EditionCityAndStation,
    idx: true,
  },

  /**** routes ****/
  {
    path: "/routes",
    component: Routes,
    idx: true,
  },
  {
    path: "/add-route",
    component: RegisterRoute,
    idx: true,
  },
  {
    path: "/edit-route/:id",
    component: EditionRoute,
    idx: true,
  },
  {
    path: "/route-detail/:id",
    component: ShowRoute,
    idx: true,
  },
  /*** inter router ***/
  {
    path: "/road-fares",
    component: InterRouteA,
    idx: true,
  },
  {
    path: "/add-road-fare",
    component: RegisterInterRouteA,
    idx: true,
  },

  {
    path: "/edit-inter-route-A1/:id",
    component: EditionInterRouteA,
    idx: true,
  },
  {
    path: "/inter-route-A1-detail/:id",
    component: ShowInterRouteA,
    idx: true,
  },
  /**** FareIncreaseRouteA2 ****/
  {
    path: "/fare-increase-hour",
    component: FareIncreaseRouteA2,
    idx: true,
  },
  {
    path: "/add-fare-increase-hour",
    component: RegisterFareIncreaseRouteA2,
    idx: true,
  },
  {
    path: "/edit-fare-increase-hour/:id",
    component: EditionFareIncreaseRouteA2,
    idx: true,
  },
  {
    path: "/fare-increase-hour-detail/:id",
    component: ShowFareIncreaseRouteA2,
    idx: true,
  },

  /** Reports **/
  {
    path: "/users-blocked-admin",
    component: BlockedAdmin,
    idx: true,
  },
  {
    path: "/users-blocked-carowner",
    component: BlockedCarOwner,
    idx: true,
  },

  /** Finance Report **/
  {
    path: "/monthly-expense-report",
    component: MonthlyExpenseReport,
    idx: true,
  },
  {
    path: "/monthly-income-report",
    component: MonthlyIncomeReport,
    idx: true,
  },

  /** Everyday Access **/
  {
    path: "/monthly-report",
    component: MonthlyReport,
    isx: true,
  },
  {
    path: "/monthly-report-carowner",
    component: MonthlyReportCarOwner,
    isx: true,
  },
  {
    path: "/monthly-report-driver",
    component: MonthlyReportDriver,
    isx: true,
  },

  /**** owner special track cars ****/

  {
    path: "/total-cars-and-trip",
    component: ListTotalCarsAndTrip,
    idx: true,
  },

  {
    path: "/separate-cars",
    component: SeparateCars,
    idx: true,
  },

  {
    path: "/category-support",
    component: CategorySupport,
    idx: true,
  },
  {
    path: "/add-category-support",
    component: RegisterCategorySupport,
    idx: true,
  },
  {
    path: "/edit-category-support/:id",
    component: EditionCategorySupport,
    idx: true,
  },
  {
    path: "/category-support-detail/:id",
    component: ShowCategorySupport,
    idx: true,
  },
  {
    path: "/commission-coefficient",
    component: CommissionCoefficient,
    idx: true,
  },
  {
    path: "/add-commission-coefficient",
    component: RegisterCommissionCoefficient,
    idx: true,
  },

  {
    path: "/drivers-commission",
    component: CommissionDriver,
    idx: true,
  },
  {
    path: "/add-commission-driver",
    component: RegisterCommissionDriver,
    idx: true,
  },
  // {
  //   path: "/edit-commission-driver/:id",
  //   component: EditCommissionDriver,
  //   idx: true,
  // },
  {
    path: "/see-commission-driver/:id",
    component: SeeCommissionDriver,
    idx: true,
  },

  {
    path: "/edit-commission-coefficient/:id",
    component: EditionCommissionCoefficient,
    idx: true,
  },
  {
    path: "/see-commission-coefficient/:id",
    component: ShowCommissionCoefficient,
    idx: true,
  },

  {
    path: "/sms",
    component: Sms,
    idx: true,
  },
  {
    path: "/sms-car-owner",
    component: SmsCarOwner,
    idx: true,
  },
  {
    path: "/send-sms",
    component: RegisterSendSms,
    idx: true,
  },
  {
    path: "/see-sms/:id",
    component: ShowSendSms,
    idx: true,
  },
  {
    path: "user-groups",
    component: UserGroups,
    idx: true,
  },
  {
    path: "add-user-groups",
    component: RegisterUserGroup,
    idx: true,
  },
  {
    path: "edit-user-groups/:id",
    component: EditionUserGroup,
    idx: true,
  },
  {
    path: "see-user-groups/:id",
    component: ShowUserGroup,
    idx: true,
  },
  {
    path: "passengers",
    component: Passenger,
    idx: true,
  },
  {
    path: "add-passenger",
    component: RegisterPassenger,
    idx: true,
  },
  {
    path: "edit-passenger/:id",
    component: EditionPassenger,
    idx: true,
  },
  {
    path: "passenger-details/:id",
    component: ShowPassenger,
    idx: true,
  },
  {
    path: "archive-passengers/:id?",
    component: ArchivePassenger,
    idx: true,
  },
  /** notification **/
  {
    path: "notifications",
    component: ListNotifications,
    idx: true,
  },
  {
    path: "send-notification",
    component: RegisterNotification,
    idx: true,
  },
  {
    path: "see-notification/:id",
    component: ShowNotification,
    idx: true,
  },
  /** orders**/
  {
    path: "orders",
    component: OrdersList,
    idx: true,
  },
  {
    path: "list-offers-by-passenger/:id",
    component: OrdersByPassengerId,
    idx: true,
  },
  {
    path: "list-offers",
    component: ListOffers,
    idx: true,
  },
  {
    path: "list-offers/:id",
    component: ListOffersById,
    idx: true,
  },
  {
    path: "archive-order",
    component: ArchiveOrdersList,
    idx: true,
  },
  {
    path: "detail-order/:id",
    component: SeeOrder,
    idx: true,
  },

  /** Stopping Time **/
  {
    path: "stopping-time",
    component: StoppingTime,
    idx: true,
  },
  {
    path: "add-stopping-time",
    component: AddStoppingTime,
    idx: true,
  },
  {
    path: "edit-stopping-time/:id",
    component: EditStoppingTime,
    idx: true,
  },
  {
    path: "see-stopping-time/:id",
    component: SeeStoppingTime,
    idx: true,
  },
  /*** gifts routes ****/
  {
    path: "reward-setting",
    component: RewardSetting,
    idx: true,
  },
  {
    path: "reward-setting/:id",
    component: EditRewardSetting,
    idx: true,
  },
  {
    path: "driver-and-passenger-fines",
    component: DriverFine,
    idx: true,
  },
  {
    path: "driver-and-passenger-fines/:id",
    component: EditionDriverFine,
    idx: true,
  },

  /*** Secretariat categories ***/
  {
    path: "secretariat-categories",
    component: SecretariatCategories,
    idx: true,
  },
  {
    path: "add-secretariat-category",
    component: RegisterSecretariatCategory,
    idx: true,
  },
  {
    path: "edit-secretariat-category/:id",
    component: EditSecretariatCategory,
    idx: true,
  },
  {
    path: "see-secretariat-category/:id",
    component: ShowSecretariatCategory,
    idx: true,
  },

  /*** departed sec ***/

  {
    path: "list-departed-secretariat",
    component: ListDeparted,
    idx: true,
  },
  {
    path: "add-departed-secretariat",
    component: RegisterDepartedSecretariat,
    idx: true,
  },
  {
    path: "see-departed-secretariat/:id",
    component: ShowDepartedSecretariat,
    idx: true,
  },
  {
    path: "edit-departed-secretariat/:id",
    component: EditDepartedSecretariat,
    idx: true,
  },
  {
    path: "edit-departed-received/:id",
    component: EditDepartedReceived,
    idx: true,
  },
  {
    path: "see-departed-received/:id",
    component: ShowDepartedReceived,
    idx: true,
  },
  {
    path: "list-departed-received",
    component: DepartedReceived,
    idx: true,
  },
  {
    path: "add-departed-received",
    component: RegisterDepartedReceived,
    idx: true,
  },

  /*** support history ***/
  {
    path: "support-history-list",
    component: SupportHistory,
    idx: true,
  },

  /*** tickets categories ***/
  {
    path: "ticket-categories",
    component: TicketCategories,
    idx: true,
  },
  {
    path: "add-ticket-category",
    component: RegisterTicketCategory,
    idx: true,
  },
  {
    path: "edit-ticket-category/:id",
    component: EditTicketCategory,
    idx: true,
  },
  {
    path: "see-ticket-category/:id",
    component: ShowTicketCategory,
    idx: true,
  },

  /*** tickets ***/
  {
    path: "tickets/:user?/:type?/:type2?",
    component: Tickets,
    idx: true,
  },
  {
    path: "not-checked-tickets/:user?/:type?/:type2?",
    component: NotCheckedTickets,
    idx: true,
  },
  {
    path: "log-tickets/:id?",
    component: LogTickets,
    idx: true,
  },
  {
    path: "add-ticket",
    component: RegisterTicket,
    idx: true,
  },
  {
    path: "add-ticket-agent",
    component: RegisterTicketAgent,
    idx: true,
  },
  {
    path: "edit-ticket/:id",
    component: EditTicket,
    idx: true,
  },
  {
    path: "edit-ticket-agent/:id",
    component: EditTicketAgent,
    idx: true,
  },
  {
    path: "see-ticket/:id",
    component: ShowTicket,
    idx: true,
  },
  {
    path: "see-full-ticket/:id",
    component: ShowFullTicket,
    idx: true,
  },
  {
    path: "see-tickets",
    component: SeeTickets,
    idx: true,
  },
  {
    path: "archive-ticket",
    component: SeeTickets,
    idx: true,
  },

  /*** reports section ***/
  {
    path: "passenger-on-map",
    component: PassengerOnMap,
    idx: true,
  },
  {
    path: "driver-on-map",
    component: DriverOnMap,
    idx: true,
  },

  /*** motion graphics ***/
  {
    path: "motion-graphics",
    component: MotionGraphicsList,
    idx: true,
  },
  {
    path: "add-motion-graphic",
    component: RegisterMotionGraphic,
    idx: true,
  },
  {
    path: "edit-motion-graphic/:id",
    component: EditionMotionGraphic,
    idx: true,
  },
  {
    path: "see-motion-graphic/:id",
    component: ShowMotionGraphic,
    idx: true,
  },

  /*** safety shield ***/
  {
    path: "safety-shields",
    component: SafetyShieldList,
    idx: true,
  },
  {
    path: "safety-shield-logs/:id",
    component: SafetyShieldLogsList,
    idx: true,
  },
  {
    path: "safety-shield/:id",
    component: SafetyShield,
    idx: true,
  },

  /***** *****/

  {
    path: "access-register-car",
    component: RegisterAccessCar,
    idx: true,
  },
  {
    path: "special-route-car-owners",
    component: SpecialRouteCarOwnerList,
    idx: true,
  },
  {
    path: "special-route-companies",
    component: specialRouteCompanyList,
    idx: true,
  },
  {
    path: "total-cars-trips",
    component: TotalCarAndTripList,
    idx: true,
  },
  // notification-list
  {
    path: "notification-list",
    component: Mynotification,
    idx: true,
  },
];
