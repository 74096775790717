import Bell from "../../Icons/headerIcon/bell/bell.icon";
import {
  StatusProfWrapper,
  UserAvatar,
} from "./headerStyles/statusHeader.style";
import LogoutIcon from "@mui/icons-material/Logout";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getAll } from "../../Utils/getAll";
// import FakeProf from "../../assets/fakeProf.png";

const statusHeader = () => {
  const Navigate = useNavigate();
  // const user = JSON.parse(localStorage.getItem("user-niko"));
  const [user, setUser] = useState([]);

  console.log("user is ", user);

  useEffect(() => {
    getAll(setUser, "/check-token");
  }, []);

  return (
    <StatusProfWrapper color="red">
      <div style={{ cursor: "pointer" }} onClick={() => Navigate("/login")}>
        <LogoutIcon />
      </div>
      <Bell
        sx={{ cursor: "pointer" }}
        onClick={() => Navigate("notification-list")}
      />
      <Typography style={{ fontSize: "12px" }} variant="subtitle2">
        {user?.name ? user.name + " " + user.lname : ""}
      </Typography>
      <UserAvatar
        onClick={() => Navigate("my-profile")}
        style={{ cursor: "pointer" }}
        alt="niko-profile-avatar"
        src={user?.photo_url ? user.photo_url : ""}
      />
    </StatusProfWrapper>
  );
};

export default statusHeader;
