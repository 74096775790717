import { Outlet, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Header from "../components/Header/header.component";
import { Main, LayoutWrapper } from "./layoutStyles/layoutStyles.style";
import SidebarMenu from "../components/Sidebar/sideBar.component";
import TopMenu from "../components/TopMenu/topMenu.component";
import { ThemeProvider } from "@mui/material";
import Theme from "./layoutThemeConfig/layoutThemeConfig.theme";
import Footer from "../components/Footer/footer.component";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosInstance from "../HelperAxios/axios";

const layout = () => {
  const [widthSidebar, setWithSidebar] = useState("f1");
  const [settings, setSettings] = useState({});
  const [dynamicBackground, setDynamicBackground] = useState("");
  const [menuColor, setMenuColor] = useState("");
  const [subMenuColor, setSubMenuColor] = useState("");

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const token = localStorage.getItem("token-niko");

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    axiosInstance.get("/settings/get").then((res) => {
      setSettings(res.data.data);
      setDynamicBackground(res.data.data.template.background);
      setMenuColor(res.data.data.template.color_menu);
      setSubMenuColor(res.data.data.template.color_submenu);
    });
  }, []);

  return (
    <ThemeProvider theme={Theme}>
      <LayoutWrapper
        dynamicBackground={dynamicBackground}
        background={pathname == "/login" && "#d5d517"}
      >
        {/* Header */}
        <Header settings={settings} />
        {/* Top menu */}
        <TopMenu setWithSidebar={setWithSidebar} />
        {/* Main content */}
        {pathname == "/login" ? (
          <Main
            width={100}
            dynamicBackground={dynamicBackground}
            background="#E5E5E9"
          >
            <Outlet />
          </Main>
        ) : (
          <Main
            widthSidebar={widthSidebar}
            dynamicBackground={dynamicBackground}
            background="#E5E5E5"
          >
            <SidebarMenu
              menuColor={menuColor}
              subMenuColor={subMenuColor}
              widthSidebar={widthSidebar}
            />
            <Outlet />
          </Main>
        )}
        {/* Footer */}
        <Footer />
      </LayoutWrapper>
      <ToastContainer rtl={true} />
    </ThemeProvider>
  );
};

export default layout;
