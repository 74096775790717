const Chevron = () => {
  return (
    <div>
      <svg
        width="12"
        height="7"
        viewBox="0 0 12 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.8231 0.104966C11.626 -0.0635617 11.3419 -0.0258563 11.1847 0.194347L6.01052 5.64201L0.829903 0.329805C0.689547 0.0993597 0.408826 0.0393201 0.201653 0.194347C-0.00888099 0.348908 -0.062227 0.661277 0.0781292 0.892188L5.60625 6.7798C5.61634 6.79609 5.62979 6.81238 5.64282 6.82867C5.64282 6.831 5.64614 6.8338 5.64951 6.83706C5.65623 6.84777 5.66634 6.85848 5.67643 6.86686C5.67979 6.87198 5.68985 6.87755 5.69321 6.88314C5.69993 6.88546 5.69995 6.89154 5.70667 6.89387C5.71003 6.89945 5.71677 6.90224 5.72013 6.90457C5.72349 6.91016 5.72687 6.91294 5.73023 6.91573C5.74704 6.92644 5.76044 6.93482 5.77389 6.94274C5.77389 6.94274 5.77726 6.94553 5.78062 6.94553C5.79071 6.95391 5.80753 6.95902 5.82055 6.9674L5.82728 6.96974C5.8609 6.98325 5.89408 6.99162 5.92434 6.9972C5.93106 6.9972 5.93107 6.9972 5.9378 6.9972C5.95124 7 5.96807 7 5.98446 7C5.98446 7 5.98783 7 5.99119 7C6.008 7 6.02478 7 6.04117 6.9972C6.0479 6.99441 6.058 6.99441 6.06473 6.99161C6.07818 6.98882 6.08822 6.98604 6.10125 6.98325C6.10798 6.98092 6.11472 6.97812 6.12144 6.97486C6.14161 6.96927 6.15466 6.96414 6.17147 6.95576C6.17483 6.95297 6.17816 6.95299 6.17816 6.95019C6.19497 6.94181 6.21474 6.9311 6.23155 6.9176C6.23491 6.9148 6.23492 6.9148 6.23828 6.9148C6.24164 6.90922 6.24833 6.90642 6.2517 6.9013C6.26178 6.89571 6.26516 6.89291 6.27189 6.885C6.28197 6.87709 6.29205 6.86593 6.30213 6.85522C6.30549 6.85243 6.30886 6.85241 6.30886 6.85008C6.31895 6.83658 6.33239 6.82263 6.34247 6.8096C6.34247 6.80681 6.34247 6.8068 6.34247 6.8068L11.9042 0.811195C12.058 0.591457 12.0235 0.276286 11.8231 0.104966Z"
          fill="#6C6C6C"
        />
      </svg>
    </div>
  );
};

export default Chevron;
