import { Box } from "@mui/material";
import PropTypes from "prop-types";

const Bell = ({ onClick, sx }) => {
  return (
    <Box component="div" sx={sx} onClick={onClick}>
      <svg
        width="23"
        height="23"
        viewBox="0 0 23 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.7585 19.59C22.7517 19.5718 22.7454 19.5581 22.7352 19.5405L21.029 16.6414C21.0091 16.6141 20.9891 16.5822 20.9658 16.5554C19.9289 15.1104 19.104 13.2218 18.5117 10.939V6.89851C18.5117 3.09385 15.4164 0 11.6051 0H11.3959C7.58914 0 4.48924 3.09385 4.48924 6.89851V10.939C3.89754 13.2218 3.07324 15.1104 2.03519 16.5554C2.01182 16.5827 1.99188 16.6095 1.97535 16.6414L0.266358 19.5405C0.259517 19.5587 0.249818 19.5724 0.242977 19.59C-0.11957 20.3464 -0.0728243 21.3477 0.359268 22.0312C0.634599 22.4657 1.04732 22.7198 1.49309 22.7198H6.80988C7.10288 25.1296 9.09405 27 11.5019 27C13.9058 27 15.8981 25.1296 16.1899 22.7198H21.5067C21.9525 22.7198 22.3681 22.4663 22.6405 22.0312C23.0732 21.3471 23.1194 20.3464 22.7568 19.59H22.7585Z"
          fill="#ECE8DC"
        />
      </svg>
    </Box>
  );
};
Bell.propTypes = {
  onClick: PropTypes.func,
  sx: PropTypes.any,
};

export default Bell;
