import { toast } from "react-toastify";
import axios from "axios";
import { API_URL_COMMON } from "../HelperAxios/const";

export const getCommon = async (setList, url) => {
  const response = await axios
    .get(`${API_URL_COMMON}/${url}`)
    .then((res) => {
      setList(res.data.data);
      return res;
    })
    .catch((err) => toast.error(err.response?.data.message));
  return response;
};
