import {
  ListItemIcon,
  ListItemText,
  styled,
  Typography,
  ListItemButton,
  List as materialList,
} from "@mui/material";
import {
  // parentColor,
  // grayLighter,
  textColor,
  // primaryColor,
  parentColor,
} from "../../../colors/allColors.color";

export const ListItemTxt = styled(ListItemText)(({ textalign }) => ({
  textAlign: textalign,
}));

export const ListIcon = styled(ListItemIcon)(({ headerlistitem }) => ({
  minWidth: headerlistitem === "true" ? "0px" : "10px",
  maxHeight: "100%",
  marginRight: "15px",
}));

export const SideBarHeaderText = styled(Typography)(() => ({
  color: textColor,
  fontFamily: "sajafFontBold",
  fontSize: "15px",
  margin: "0 auto",
}));

export const ListItemBtn = styled(ListItemButton)(
  ({
    aschidlren,
    asparent,
    headerlistitem,
    isactive,
    menuColor,
    subMenuColor,
  }) => ({
    backgroundColor:
      isactive === "true"
        ? parentColor
        : asparent
        ? menuColor //the main color before get's dynamic is 'parentColor'
        : aschidlren === "true"
        ? subMenuColor //the main color before get's dynamic is 'grayLighter'
        : headerlistitem === "true"
        ? "#FFFFFF"
        : menuColor, //the main color before get's dynamic is 'parentColor'
    height: headerlistitem === "true" ? "45px" : "40px",
    "& span": {
      fontSize: "11px",
      fontWeight: aschidlren ? "nomal" : "bold",
    },
    // borderLeft: isactive === "true" ? `6px solid ${primaryColor}` : 0,
    fontWeight: aschidlren ? "nomal" : "bolder",
  })
);

/**
 * @param {string} isactive
 */
export const List = styled(materialList)(({ isactive, menuColor }) => ({
  // borderLeft: isactive === "true" ? `6px solid ${primaryColor}` : 0,
  backgroundColor: isactive === "true" ? parentColor : menuColor,
}));
