import { EyeContainer } from "./eyeStyles/eye.style";
import Proptypes from "prop-types";

const Eye = ({ onClick }) => {
  return (
    <EyeContainer style={{ cursor: "pointer" }} onClick={onClick}>
      <svg
        width="28"
        height="19"
        viewBox="0 0 36 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask id="path-1-inside-1_702_1931" fill="white">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.0439 0.583679C10.4894 0.583679 3.69157 4.47024 0.351562 10.5837H0.354443C3.69532 16.6971 10.4923 20.5837 18.0467 20.5837C25.6012 20.5837 32.3991 16.6971 35.7391 10.5837L35.7362 10.5837C32.3953 4.47024 25.5983 0.583679 18.0439 0.583679Z"
          />
        </mask>
        <path
          d="M0.351562 10.5837L-0.526007 10.1042L-1.33429 11.5837H0.351562V10.5837ZM0.354443 10.5837L1.23196 10.1041L0.947542 9.58368H0.354443V10.5837ZM35.7391 10.5837L36.6166 11.0631L37.4274 9.57919L35.7364 9.58368L35.7391 10.5837ZM35.7362 10.5837L34.8587 11.0632L35.1439 11.5853L35.7388 11.5837L35.7362 10.5837ZM1.22913 11.0631C4.3795 5.29679 10.8243 1.58368 18.0439 1.58368V-0.416321C10.1546 -0.416321 3.00363 3.64369 -0.526007 10.1042L1.22913 11.0631ZM0.354443 9.58368H0.351562V11.5837H0.354443V9.58368ZM18.0467 19.5837C10.8273 19.5837 4.38323 15.8706 1.23196 10.1041L-0.523074 11.0632C3.0074 17.5236 10.1574 21.5837 18.0467 21.5837V19.5837ZM34.8615 10.1042C31.7111 15.8706 25.2663 19.5837 18.0467 19.5837V21.5837C25.9361 21.5837 33.087 17.5237 36.6166 11.0631L34.8615 10.1042ZM35.7388 11.5837L35.7417 11.5837L35.7364 9.58368L35.7335 9.58368L35.7388 11.5837ZM18.0439 1.58368C25.2634 1.58368 31.7074 5.29674 34.8587 11.0632L36.6137 10.1041C33.0832 3.64374 25.9333 -0.416321 18.0439 -0.416321V1.58368Z"
          fill="#545454"
          mask="url(#path-1-inside-1_702_1931)"
        />
        <circle cx="17.8594" cy="10.5837" r="8.25" stroke="#545454" />
      </svg>
    </EyeContainer>
  );
};
Eye.propTypes = {
  onClick: Proptypes.func,
};

export default Eye;
