import React, { useEffect, useState } from "react";
import TextBox from "../../components/Inputs/TextBox/textBox.component";
import PersonIcon from "../../Icons/FormsIcon/Person/person.icon";
import EyeIcon from "../../Icons/FormsIcon/Eye/eye.icon";
import Button from "../../components/Inputs/Button/button.component";
import { primaryColor, violet } from "../../colors/allColors.color";
import { useMediaQuery } from "@mui/material";
import {
  LoginContainer,
  RightSide,
  FormWrapper,
  FieldWrapper,
  ForgottenText,
  LeftSide,
  LoginText,
  LoginTextContainer,
  FleetsRegister,
} from "./loginStyles/login.style";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import LoginImage from "../../assets/loginPic.jpg";
import StrinLocalized from "../../Language/language.lang";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { API_URL_PORTAL } from "../../HelperAxios/const";
import { getCommon } from "../../Utils/getCommon";
import useSignIn from "react-auth-kit/hooks/useSignIn";

const Login = () => {
  const Navigate = useNavigate();
  const showLeftBox = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const signIn = useSignIn();

  const showPasswordHandler = () => {
    setVisible(!visible);
  };

  /*** handle localStorage Setting ***/
  localStorage.removeItem("token-niko");
  localStorage.removeItem("tokenR-niko");
  localStorage.removeItem("user-niko");
  localStorage.removeItem("role-niko");
  localStorage.removeItem("permissions-niko");
  const { handleSubmit, control } = useForm();
  const [setting, setSetting] = useState();
  useEffect(() => {
    getCommon(setSetting, "settings/read");
  }, []);

  console.log("setting ====> ", setting);

  const onSubmit = (data) => {
    toast.info(StrinLocalized.sendData);
    console.log(data);
    axios
      .post(API_URL_PORTAL + "/auth/login", data)
      .then((res) => {
        let data = res.data.data;
        console.log(data);
        if (data) {
          localStorage.setItem("token-niko", data.token.access_token);
          localStorage.setItem(
            "token-niko",
            JSON.stringify(data.token.access_token)
          );
          if (
            signIn({
              auth: { token: data.token.access_token, type: "Bearer" },
              userState: data.user,
              refresh_token: data.token.access_token,
            })
          ) {
            setTimeout(() => {
              if (
                data.user.role === "صاحب ماشین" ||
                data.user.role === "نماینده ماشین"
              ) {
                navigate("/dashboard/carowner");
              } else if (data.user.role === "راننده خودرو") {
                navigate("/dashboard/driver");
              } else if (data.user.role === "نماینده شرکت") {
                navigate("/dashboard/company");
              } else {
                navigate("/dashboard/admin");
              }
            }, 2500);
          } else {
            toast.error("Something went wrong");
          }
          localStorage.setItem("user-niko", JSON.stringify(data?.user));
          localStorage.setItem("role-niko", btoa(data?.roles));
          localStorage.setItem("permissions-niko", btoa(data?.permissions));
        }
        toast.success(StrinLocalized.SuccessLogin);
      })
      .catch((error) => {
        toast.error(error.response?.data.message);
      });
    // axios
    //   .post("https://apipikgo.akafweb.com/api/v1/portal/auth/login", data, {
    //   })
    //   .then((res) => {
    //     let data = res.data.data;
    //     console.log(data);
    //     if (data) {
    //       localStorage.setItem("token-niko", JSON.stringify(data.token.access_token));
    //       localStorage.setItem(
    //         "tokenR-niko",
    //         JSON.stringify(data.token.access_token)
    //       );
    //       if (
    //         signIn({
    //           auth: {
    //             token: data.token.token,
    //             type: "Bearer",
    //           },

    //           userState: data.user,
    //           refresh_token: data.token.access_token,
    //         })
    //       ) {
    //         setTimeout(() => {
    //           if (
    //             data.user.role === "صاحب ماشین" ||
    //             data.user.role === "نماینده ماشین"
    //           ) {
    //             navigate("/dashboard/carowner");
    //           } else if (data.user.role === "راننده خودرو") {
    //             navigate("/dashboard/driver");
    //           } else if (data.user.role === "نماینده شرکت") {
    //             navigate("/dashboard/company");
    //           } else {
    //             navigate("/dashboard/admin");
    //           }
    //         }, 2500);
    //       } else {
    //         toast.error("Something went wrong");
    //       }
    //       localStorage.setItem("user-niko", JSON.stringify(data.user));
    //       // localStorage.setItem("role-niko", btoa(data.roles));
    //       // localStorage.setItem("permissions-niko", btoa(data.permissions));
    //     }
    //     toast.success(StrinLocalized.SuccessLogin);
    //   })
    //   .catch((err) => {
    //     toast.error(err.response?.data.message);
    //   });
  };

  return (
    <div>
      <LoginContainer>
        {showLeftBox && (
          <LeftSide
            backgroundPhoto={
              setting?.portalLoginPage?.photoUrl
                ? setting?.portalLoginPage?.photoUrl
                : LoginImage
            }
          >
            <LoginTextContainer>
              <LoginText>
                {" "}
                {setting?.portalLoginPage?.title
                  ? setting.portalLoginPage.title
                  : ""}{" "}
              </LoginText>
              <LoginText>
                {" "}
                {setting?.portalLoginPage?.text
                  ? setting.portalLoginPage.text
                  : ""}{" "}
              </LoginText>
            </LoginTextContainer>
          </LeftSide>
        )}
        <RightSide>
          {" "}
          <FormWrapper>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {/* <h1>{StrinLocalized.LoginLabel}</h1> */}
                <img src={setting?.template?.logo} alt="logo" width={110} />
              </div>
              <FieldWrapper>
                <Controller
                  name="username"
                  control={control}
                  render={({ field: { onChange, value = "" } }) => (
                    <TextBox
                      id="username"
                      name="username"
                      placeHolder={StrinLocalized.UserName}
                      width={450}
                      height={45}
                      centerText
                      rounded
                      icon={<PersonIcon />}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </FieldWrapper>
              <FieldWrapper>
                <Controller
                  name="password"
                  control={control}
                  render={({ field: { onChange, value = "" } }) => (
                    <TextBox
                      id="password"
                      name="password"
                      placeHolder={StrinLocalized.Password}
                      width={450}
                      height={45}
                      rounded
                      icon={<EyeIcon onClick={showPasswordHandler} />}
                      centerText
                      password={!visible}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </FieldWrapper>
              <FieldWrapper>
                <Button
                  buttonText={StrinLocalized.SubmitLogin}
                  width={450}
                  height={45}
                  background={violet}
                  textcolor="#FFFFFF"
                  rounded
                  type={"submit"}
                />
              </FieldWrapper>
              {/* <FieldWrapper>
                <Button
                  buttonText={StrinLocalized.SubmitLoginByPhone}
                  width={450}
                  height={45}
                  background={primaryColor}
                  textcolor="#FFFFFF"
                  rounded
                  click={() => {
                    Navigate("/login-by-phone");
                  }}
                />
              </FieldWrapper> */}
              <FieldWrapper>
                <FleetsRegister
                  onClick={() => {
                    Navigate("/add-car-owner-login");
                  }}
                >
                  Fleets Register
                </FleetsRegister>
                <ForgottenText
                  onClick={() => {
                    Navigate("/login-by-phone");
                  }}
                >
                  Forget Password
                </ForgottenText>
              </FieldWrapper>
            </form>
          </FormWrapper>
        </RightSide>
      </LoginContainer>
      <ToastContainer rtl={true} />
    </div>
  );
};

export default Login;
