import React from "react";
import { useRef, useState } from "react";
import TextBox from "../TextBox/textBox.component";
import PropTypes from "prop-types";
import Button from "../Button/button.component";
import { UploadContainer } from "./UploadInputStyles/UpladoInput.style";
import { Tooltip } from "@mui/material";
import HelpTwoToneIcon from "@mui/icons-material/HelpTwoTone";

const UploadInput = ({
  width,
  widthButton,
  height,
  placeholder,
  uploadValue,
  index,
  DeleteFileHandle,
  isNeedDelete = false,
  noToolTip,
  downloadHandler,
  downLoadButton,
  text,
  sx,
}) => {
  const longText = "Maximum file size sent: 2 MB and photo dimensions: 600x800";
  const uploadRef = useRef();
  const [fileName, setFileName] = useState("");

  return (
    <React.Fragment>
      <input
        type="file"
        placeholder="Upload"
        style={{ display: "none" }}
        ref={uploadRef}
        onChange={(e) => {
          setFileName(e.target.files["0"].name);
          uploadValue({ file: e.target.files[0], index: index });
        }}
      />
      <UploadContainer>
        <TextBox
          width={width}
          height={height}
          placeHolder={fileName ? fileName : placeholder}
          keyPress={(e) => e.preventDefault()}
          onChange={(e) => e.preventDefault()}
          sx={sx}
        />
        <Button
          click={() => uploadRef.current.click()}
          buttonText="Upload"
          width={63}
          background="#DaD4C4"
        />

        {isNeedDelete ? (
          <Button
            buttonText="حذف"
            width={63}
            marginRight={5}
            click={() => DeleteFileHandle(index)}
          />
        ) : (
          <></>
        )}

        {downLoadButton && (
          <Button
            marginRight={10}
            click={downloadHandler}
            buttonText={text}
            width={widthButton}
            // background="#DaD4C4"
          />
        )}

        {!noToolTip && (
          <Tooltip
            leaveDelay={200}
            style={{
              marginRight: "15px",
              marginTop: "5px",
              color: "grey",
            }}
            title={longText}
            placement="top"
          >
            <HelpTwoToneIcon />
          </Tooltip>
        )}
      </UploadContainer>
    </React.Fragment>
  );
};

UploadInput.propTypes = {
  width: PropTypes.number,
  widthButton: PropTypes.number,
  height: PropTypes.number,
  placeholder: PropTypes.string,
  text: PropTypes.string,
  uploadValue: PropTypes.any,
  sx: PropTypes.any,
  DeleteFileHandle: PropTypes.any,
  isNeedDelete: PropTypes.bool,
  index: PropTypes.number,

  noToolTip: PropTypes.bool,
  downLoadButton: PropTypes.bool,
  downloadHandler: PropTypes.func,
};

export default UploadInput;
